export default ($axios) => ({
  // Получение списка контролов для формы
  getControls(source = 'otrs1') {
    return $axios
      .get(`/admin/controls/?source=${source}`)
      .then((res) => res);
  },
  // Получение списка контролов для опросов
  getSurveyControls() {
    return $axios
      .get('/survey/controls')
      .then((res) => res);
  },
  // Получение списка контролов для опросов ИБ
  getIndexSurveyControls() {
    return $axios
      .get('/ckiz/ib-surveys/controls')
      .then((res) => res);
  },
  // Получение списка контролов для формы
  getApprovalControls() {
    return $axios
      .get('/admin/approvals/controls')
      .then((res) => res);
  },
  exportValues(values, source = 'otrs1') {
    return $axios
      .post(`/admin/export-list-values?source=${source}`, {
        values,
      }, {
        responseType: 'blob',
      }).then((res) => res);
  },
  importValues(formDto, source = 'otrs1') {
    return $axios
      .post(`/admin/import-list-values?source=${source}`, formDto)
      .then((res) => res);
  },
  // Валидация формулы для контрола Расчётное поле
  validateCalculationFormula(value) {
    return $axios
      .post('/calculation-formula/validate', { value })
      .then((res) => res);
  },
  // Вычисление значения для контрола Расчётное поле
  calculateFormulaResult({
    sourceSystem,
    formId,
    formVersionId,
    checklistItemId,
    checklistItems,
    formType,
  }) {
    return $axios
      .post('/calculation-formula/calculate', {
        sourceSystem,
        formId,
        formVersionId,
        checklistItemId,
        checklistItems,
        formType,
      })
      .then((res) => res);
  },
});
