import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';

const dateFormat = (date, format = 'DD.MM.YYYY HH:mm') => {
  if (dayjs(date, 'YYYY-MM-DD HH:mm:ssZZ').isValid()) {
    return dayjs(date, 'YYYY-MM-DD HH:mm:ssZZ').format(format);
  }
  if (dayjs(date).isValid()) return dayjs(date).format(format);
  console.error(`Invalid Date "${date}"`);
  return '';
};

export const getUTCToLocalDate = (date, format) => {
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  return dayjs(date).local().format(format);
};

export const updateDayjsLocale = (localeName, config) => {
  dayjs.extend(updateLocale);
  dayjs.updateLocale(localeName, config);
};

export default dateFormat;
