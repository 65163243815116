const state = {
  subscriptions: [],
};

const getters = {};

const mutations = {
  SET_SUBSCRIPTIONS(state, payload) {
    state.subscriptions = payload;
  },

  CLEAR_SUBSCRIPTIONS(state) {
    state.subscriptions = [];
  },
};

const actions = {
  setSubscriptions({ commit }, payload) {
    commit('SET_SUBSCRIPTIONS', payload);
  },

  clearSubscriptions({ commit }) {
    commit('CLEAR_SUBSCRIPTIONS');
  },
};

const subscriptions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default subscriptions;
