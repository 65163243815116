<template>
  <div id="app">
    <old-browser-info v-if="isIE" />
    <layout-dynamic />
  </div>
</template>

<script>
import LayoutDynamic from '@/layouts/LayoutDynamic.vue';
import OldBrowserInfo from '@/components/old-browser-info';

export default {
  name: 'App',

  components: { LayoutDynamic, OldBrowserInfo },

  created() {
    this.isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  },
};
</script>
