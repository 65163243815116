import Vue from 'vue';
import VueMeta from 'vue-meta';
import { mapActions, mapGetters, mapState } from 'vuex';
import throttle from 'lodash/throttle';
import App from '@/App.vue';
import Preloader from '@/Preloader.vue';
import router from '@/router';
import store from '@/store';
import Hub from '@/plugins/event-hub';
import api from '@/services/api';
import '@/plugins';
import '@/filters';
import '@/hoc';
import '@/assets/scss/index.scss';
import setPortalStyle from '@/helpers/set-style.helper';
import { APP_STATES } from '@/constants/app';
import {
  isErrorPage, isForgetPasswordPage, isLoginPage, isResetPasswordPage,
} from '@/helpers/url-path.helper';

Vue.config.productionTip = false;
store.$API = api;
Vue.prototype.$API = api;

window.HM_LAST_UNIQID = Math.round(new Date() / 1000) - Math.round(new Date('2018-06-01') / 1000);
// eslint-disable-next-line
window.HM_CURRENT_UNIQID = () => (window.HM_LAST_UNIQID += 1);

Vue.use(VueMeta);

new Vue({
  router,
  store,
  render() {
    const PreloaderComponent = <Preloader/>;
    const AppComponent = <App/>;
    let appState = APP_STATES.preload;

    if (this.isAppInitComplete) {
      appState = APP_STATES.isAppInitComplete;
    }

    switch (appState) {
    case APP_STATES.isAppInitComplete:
      return AppComponent;
    case APP_STATES.preload:
    default:
      return PreloaderComponent;
    }
  },
  metaInfo() {
    return {
      title: this.portalTitle,
      titleTemplate: null,
      link: [
        {
          rel: 'icon',
          href: `${this.portalFavicon}?v=${+new Date()}`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('system', ['portalTitle', 'portalFavicon', 'settingsUI']),
    ...mapState('user', ['isUserLoggedIn']),
    ...mapState('system', ['isAppInitComplete']),
  },
  methods: {
    ...mapActions('viewOptions', ['setWindowSizes', 'setViewMode']),
    ...mapActions('system', ['getAppSettings', 'getUserSettings', 'setIsAppInitComplete']),
    // eslint-disable-next-line
    resizeHandler: throttle(function () {
      this.setWindowSizes(window.innerWidth);
    }, 500),
  },
  watch: {
    $route: {
      handler(to) {
        document.title = to.meta?.title
          ? `${to.meta.title} | ${this.portalTitle}`
          : `${this.portalTitle} - сервисный портал`;

        this.setViewMode(to.query.viewMode);
      },
      immediate: true,
    },
    settingsUI: {
      handler(value) {
        if (value) {
          setPortalStyle(this.$EsmpTheming, this.settingsUI);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    try {
      // получение настроек системы
      await this.getAppSettings();

      // получение настроек пользователя только для страниц, отличных от Auth/Error
      // если 401, то кинет на логин
      if (
        !isErrorPage(window.location.pathname)
        && !isLoginPage(window.location.pathname)
        && !isForgetPasswordPage(window.location.pathname)
        && !isResetPasswordPage(window.location.pathname)
      ) {
        await this.getUserSettings();
      }
    } finally {
      // в любом случае завершить инициализацию приложения (закрыть лоадер)
      this.setIsAppInitComplete();
    }

    // Events
    Hub.$on('show-message', ({ message, type, options }) => {
      this.$EsmpNotify.$show(message, type, options);
    });
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  },
  beforeDestroy() {
    Hub.$off('show-message');
    window.removeEventListener('resize', this.resizeHandler);
  },
}).$mount('#app');
