export const MODES = {
  one: {
    text: 'Одно workflow/одна очередь',
    value: 'single',
  },
  many: {
    text: 'Множественное workflow/Множественная очередь (Архивный способ)',
    value: 'multiple',
  },
  booleanMany: {
    text: 'Мультизависимость для workflow/очередей',
    value: 'booleanMultiple',
  },
};

export const BAND_TYPES = {
  workflow: 'Workflow',
  queue: 'Очередь',
  queueGroup: 'Группа очередей',
};

export const CONDITION_TYPES = {
  and: {
    text: 'И',
    value: 'and',
  },
  or: {
    text: 'ИЛИ',
    value: 'or',
  },
};

export const DEFAULT_CONTROL_NAME = 'Название';
