export const PORTAL_DEFAULT_NAME = 'ESMP Client Portal';

export const TOOLBAR_LINK_TYPES = {
  REQUEST_FROM: 'statementOnBehalf',
  DELEGATIONS: 'delegation',
  BUSINESS_TRIP: 'businessTripOrder',
  PORTFOLIO: 'serviceCatalog',
};

export const TOOLBAR_LINKS = {
  [TOOLBAR_LINK_TYPES.REQUEST_FROM]: {
    code: TOOLBAR_LINK_TYPES.REQUEST_FROM,
    icon: 'user-man',
    title: 'Заявка от имени',
    modal: 'request-from',
  },
  [TOOLBAR_LINK_TYPES.DELEGATIONS]: {
    code: TOOLBAR_LINK_TYPES.DELEGATIONS,
    icon: 'delivery-courier',
    title: 'Делегирование',
    path: '/delegations',
  },
  [TOOLBAR_LINK_TYPES.BUSINESS_TRIP]: {
    code: TOOLBAR_LINK_TYPES.BUSINESS_TRIP,
    icon: 'delivery-bus',
    title: 'Заказ командировки',
    path: '/business-trip',
  },
  [TOOLBAR_LINK_TYPES.PORTFOLIO]: {
    code: TOOLBAR_LINK_TYPES.PORTFOLIO,
    icon: 'portfolio',
    title: 'Каталог услуг',
    path: '/tickets/create',
  },
};

// Временное решение, пока на беке не реализован функциоанл хранения иконок для каждой кнопки bannerLinks
export const BANNER_LINKS = {
  statementOnBehalf: {
    icon: 'user-man',
  },
  delegation: {
    icon: 'delivery-courier',
  },
  serviceCatalog: {
    icon: 'portfolio',
  },
  businessTripOrder: {
    icon: 'delivery-bus',
  },
};

export default {
  TOOLBAR_LINKS,
  BANNER_LINKS,
};
