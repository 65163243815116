import Hub from '@/plugins/event-hub';
import router from '@/router';
import store from '@/store';
import httpClient from '@/plugins/axios/httpClient';

const showError = (message) => {
  Hub.$emit('show-message', { message, type: 'error', options: { duration: 10000 } });
};

export default async (error) => {
  const { response } = error;
  const status = response?.status || null;
  const messageForUser = response?.data?.messageForUser || null;

  if (status === 401 && !['Login', 'Error'].includes(router.currentRoute.name)) {
    if (httpClient.abortController) {
      httpClient.abortController.abort();
      httpClient.reload(1000);
    }

    if (messageForUser) {
      showError(messageForUser);
    } else if (store.state['user/isUserLoggedIn']) {
      showError('Ошибка авторизации');
    }

    await store.dispatch('auth/logout');
  }

  if (status === 400) showError(messageForUser || 'Поля формы заполнены некорректно');
  if (status === 403) showError(messageForUser || 'Доступ запрещен');
  if (status === 404) showError(messageForUser || 'Endpoint не найден');
  if (status === 405) showError(messageForUser || 'Метод не поддерживается');

  if (status > 405 && status < 500) showError(messageForUser || 'Неизвестная ошибка');

  if (status >= 500) {
    if (response && response.config && response.config.url === '/api/auth') {
      showError(`Не удалось выполнить проверку учётных данных. ${messageForUser}`);
      await store.dispatch('auth/logout');
    } else {
      showError(messageForUser || 'Внутренняя ошибка сервера');
    }
  }

  return Promise.reject(error);
};
