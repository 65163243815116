import Vue from 'vue';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { REFERENCE, businessTrip } from '@/constants/businessTrip';

export const state = {
  targets: null,
  cfd: null,
  businessProcesses: null,
  codePrograms: null,
  limits: null,
  constants: null,
  freeFund: null,
  activeDestination: null,
  expenses: {
    cities: [],
    organization: {
      id: 'ПАО «Ростелеком»',
      name: 'ПАО «Ростелеком»',
    },
    otherOrganization: '',
    commonDayStart: null,
    commonDayEnd: null,
    isSelfBooking: false,
    isNeedHelp: false,
    groupBooking: false,
    avansAmount: '',
    avansAmountComment: null,
  },
  transit: [],
};

export const getters = {
  targets: () => {
    if (state.targets && state.targets.length) {
      return state.targets.map((target, index) => ({
        id: `target_${index}`,
        name: target.name ? `${target.name}` : null,
        description: target.description ? `${target.description}` : null,
      }));
    }
    return null;
  },
  cfd: () => {
    if (state.cfd && state.cfd.length) {
      return state.cfd.map((cfd, index) => ({
        id: `cfd_${index}`,
        name: cfd.name ? `${cfd.name}` : null,
        code: cfd.code ? `${cfd.code}` : null,
        shortName: cfd.shortName ? `${cfd.shortName}` : null,
      }));
    }
    return null;
  },
  businessProcesses: () => {
    if (state.businessProcesses && state.businessProcesses.length) {
      return state.businessProcesses.map((process) => ({
        id: process.businessProcessId ? `${process.businessProcessId}` : null,
        name: process.businessProcessName
          ? `${process.businessProcessName}`
          : null,
      }));
    }
    return null;
  },
  territorialZones: () => {
    if (state.limits && state.limits.length) {
      return state.limits.map((limit) => ({
        id: limit.code ? `${limit.code}` : null,
        name: limit.regionName ? `${limit.regionName}` : null,
      }));
    }
    return null;
  },
  editableDestination: () => {
    if (state.activeDestination) {
      let block = null;
      // eslint-disable-next-line default-case
      switch (state.activeDestination.block) {
      case businessTrip.DESTINATION.expenses:
        block = state.expenses.cities[state.activeDestination.index || 0];
        break;
      case businessTrip.DESTINATION.transitStart:
        block = state.transit[state.activeDestination.index || 0].startPoint;
        break;
      case businessTrip.DESTINATION.transitEnd:
        block = state.transit[state.activeDestination.index || 0].endPoint;
        break;
      case businessTrip.DESTINATION.accommodation:
        break;
      }

      return block || null;
    }

    return null;
  },
  avansComments: () => (state.constants ? state.constants.prepaymentComment : []),
  organizations: () => (state.constants ? state.constants.organizations : []),
  articles: () => (state.constants ? state.constants.articles : []),
  timeOfDay: () => (state.constants ? state.constants.timeOfDay : []),
  freeFund: () => (state.freeFund && state.freeFund.funds
    ? Number(state.freeFund.funds).toLocaleString('RU-ru')
    : null),
};

export const mutations = {
  setTargets(state, targets) {
    state.targets = targets;
  },
  setCfd(state, cfd) {
    state.cfd = cfd;
  },
  setBusinessProcesses(state, businessProcesses) {
    state.businessProcesses = businessProcesses;
  },
  setLimits(state, limits) {
    state.limits = limits;
  },
  setConstants(state, comments) {
    state.constants = comments;
  },
  setActiveDestination(state, destination) {
    state.activeDestination = destination;
  },
  setFreeFund(state, freeFund) {
    state.freeFund = freeFund;
  },

  addExpensesCity(state) {
    // eslint-disable-next-line prefer-object-spread
    const expensesCity = Object.assign({}, REFERENCE.expensesCity);
    const now = new Date();
    expensesCity.id = `expensesCity_${now.getTime()}`;

    const cities = [...state.expenses.cities];
    cities.push(expensesCity);
    Vue.set(state.expenses, 'cities', cities);
  },
  removeExpensesCity(state, index) {
    const numberIndex = Number(index);
    const cities = [...state.expenses.cities];
    cities.splice(numberIndex, 1);

    Vue.set(state.expenses, 'cities', cities);
  },
  updateExpensesCity(state, { index, city }) {
    const numberIndex = Number(index);
    const cities = [...state.expenses.cities];
    cities.splice(numberIndex, 1, city);

    Vue.set(state.expenses, 'cities', cities);
  },
  updateExpenses(state, { field, value }) {
    Vue.set(state.expenses, field, value);
  },
  clearExpenses(state) {
    // eslint-disable-next-line prefer-object-spread
    const clearExpenses = Object.assign({}, REFERENCE.expenses);
    Vue.set(state, 'expenses', clearExpenses);
  },
  addTransit(state, addingPoint) {
    // eslint-disable-next-line prefer-object-spread
    const copyTransitReference = Object.assign({}, REFERENCE.transit);

    const now = new Date();
    copyTransitReference.id = `TransitReference_${now.getTime()}`;

    const transit = [...state.transit];
    transit.push(copyTransitReference);
    Vue.set(state, 'transit', transit);

    if (addingPoint) {
      const index = state.transit.length - 1;
      const currentStartPoint = state.transit[index].startPoint;
      const prevEndPoint = index ? state.transit[index - 1].endPoint : null;
      if (index && isEmpty(currentStartPoint) && !isEmpty(prevEndPoint)) {
        Vue.set(
          state.transit[index],
          'startPoint',
          JSON.parse(JSON.stringify(prevEndPoint)),
        );
      }
    }
  },
  removeTransitBlock(state, index) {
    const numberIndex = Number(index);
    const transit = [...state.transit];
    transit.splice(numberIndex, 1);

    Vue.set(state, 'transit', transit);
  },
  updateTransit(state, { index, field, value }) {
    Vue.set(state.transit[index], field, value);
  },
  clearTransit(state) {
    Vue.set(state, 'transit', []);
  },
  SET_CODE_PROGRAMS(state, programs) {
    state.codePrograms = programs;
  },
};

export const actions = {
  getTargets({ commit }) {
    return axios.get('/api/business-trip/goals').then((response) => {
      commit('setTargets', response.data);
    });
    // .catch(() => null);
  },
  getCfd({ commit }) {
    return axios.get('/api/business-trip/cfos').then((response) => {
      commit('setCfd', response.data);
    });
    // .catch(() => null);
  },
  getBusinessProcesses({ commit }, params) {
    axios
      .get('/api/business-trip/business-processes', {
        params,
      })
      .then((response) => {
        commit('setBusinessProcesses', response.data);
      });
    // .catch(() => null);
  },
  getCodePrograms({ commit }, params) {
    axios
      .get('/api/business-trip/program-codes', {
        params,
      })
      .then((response) => {
        commit('SET_CODE_PROGRAMS', response.data);
      });
    // .catch(() => null);
  },
  getLimits({ commit }, params) {
    return axios
      .get('/api/business-trip/limits', {
        params,
      })
      .then((response) => {
        commit('setLimits', response.data);

        return response;
      });
    // .catch(() => null);
  },
  getConstants({ commit }) {
    return axios.get('/api/business-trip/constants').then((response) => {
      commit('setConstants', response.data);

      return response;
    });
    // .catch(() => null);
  },
  setActiveDestination({ commit }, destination) {
    commit('setActiveDestination', destination);
  },
  getRegion(context, regionCode) {
    return axios
      .get('/api/business-trip/addresses', {
        params: {
          searchType: 0, // Region
          regionCode,
        },
      })
      .then((response) => response.data[0])
      .catch((e) => e);
  },
  getArea(context, { regionCode, areaCode }) {
    return axios
      .get('/api/business-trip/addresses', {
        params: {
          searchType: 1, // Area
          regionCode,
          districtCode: areaCode,
        },
      })
      .then((response) => response.data[0])
      .catch((e) => e);
  },
  getFreeFund({ commit }, params) {
    axios
      .get('/api/business-trip/free-funds', {
        params,
      })
      .then((response) => {
        commit('setFreeFund', response.data);
      });
    // .catch(() => null);
  },
  getBookingRef({ commit }, { ticketId, email }) {
    axios
      .get(`/api/getBookingRef/${ticketId}`, {
        params: { email },
      })
      .then((response) => {
        commit('setFreeFund', response.data);
      });
    // .catch(() => null);
  },
  addExpensesCity({ commit }) {
    commit('addExpensesCity');
  },
  removeExpensesCity({ commit }, index) {
    commit('removeExpensesCity', index);
  },
  updateExpensesCity({ commit }, { index, city }) {
    commit('updateExpensesCity', { index, city });
  },
  updateExpenses({ commit }, { field, value, list }) {
    let val = value;
    if (list && list.length) {
      val = list.find((i) => i.id === value);
    }
    commit('updateExpenses', { field, value: val });
  },
  clearExpenses({ commit }) {
    commit('clearExpenses');
  },
  addTransit({ commit }, addingPoint) {
    commit('addTransit', addingPoint);
  },
  removeTransitBlock({ commit }, index) {
    commit('removeTransitBlock', index);
  },
  updateTransit({ commit }, { index, field, value }) {
    commit('updateTransit', { index, field, value });
  },
  clearTransit({ commit }) {
    commit('clearTransit');
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
