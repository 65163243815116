import localStorageDb from '@/utils/localStorageDB';
import { USER_ROLES, USER_ROLES_TYPES } from '@/constants/user';

const state = {
  loggedInUser: null, // залогиненный в систему пользователь
  selectedUser: null, // выбранный пользователь для делегирования
  isUserLoggedIn: localStorageDb.get('isUserLoggedIn') || false,
  roles: [], // массив доступных ролей на портале
};

const getters = {
  selectedOrLoggedInUser(state) {
    return (state.selectedUser ? state.selectedUser : state.loggedInUser);
  },
  isRequestFromMode(state) {
    return !!state.selectedUser;
  },
  mappedRoles: () => state.roles.map((item) => USER_ROLES[item]),
};

const mutations = {
  SET_USER(state, val) {
    state.loggedInUser = val;
    // eslint-disable-next-line max-len
    localStorageDb.put('isAdmin', val?.roles ? val.roles.some((role) => (role === USER_ROLES_TYPES.ADMIN) || (role === USER_ROLES_TYPES.SUPER)) : false);
  },
  SET_SELECTED_USER(state, user) {
    state.selectedUser = user;
  },
  SET_IS_USER_LOGGED_IN(state, payload) {
    state.isUserLoggedIn = payload;
    localStorageDb.put('isUserLoggedIn', payload);
  },
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
};

const actions = {
  clearUser({ commit }) {
    commit('SET_USER', null);
  },
  setSelectedUser({ commit }, user) {
    commit('SET_SELECTED_USER', user);
  },
  clearSelectedUser({ commit }) {
    commit('SET_SELECTED_USER', null);
  },
  setIsUserLoggedIn({ commit }, payload) {
    commit('SET_IS_USER_LOGGED_IN', payload);
  },
  async getRoles({ commit }) {
    try {
      const { data: roles } = await this.$API.user.getRoles();
      commit('SET_ROLES', roles);
    } catch (e) {
      throw new Error(e);
    }
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
