import user from '@/services/api/User';
import system from '@/services/api/System';
import services from '@/services/api/Services';
import approval from '@/services/api/Approval';
import businessTrip from '@/services/api/BusinessTrip';
import cmdb from '@/services/api/Cmdb';
import checklist from '@/services/api/Checklist';
import delegation from '@/services/api/Delegation';
import different from '@/services/api/Different';
import employeeInfo from '@/services/api/EmployeeInfo';
import employeeReplace from '@/services/api/EmployeeReplace';
import escalation from '@/services/api/Escalation';
import feedback from '@/services/api/Feedback';
import fias from '@/services/api/Fias';
import fileStorage from '@/services/api/FileStorage';
import log from '@/services/api/Log';
import news from '@/services/api/News';
import resource from '@/services/api/Resource';
import ticket from '@/services/api/Ticket';
import userTemplates from '@/services/api/UserTemplates';
import faq from '@/services/api/Faq';
import feasts from '@/services/api/Feasts';
import articles from '@/services/api/Articles';
import employeeSettings from '@/services/api/EmployeeSettings';
import database from '@/services/api/Database';
import resetPassword from '@/services/api/ResetPassword';
import admin from '@/services/api/Admin';
import forms from '@/services/api/Forms';
import controls from '@/services/api/Controls';
import portalStyle from '@/services/api/PortalStyle';
import subscriptions from '@/services/api/Subscriptions';
import workflow from '@/services/api/WorkFlow';
import popular from '@/services/api/Popular';
import survey from '@/services/api/Survey';
import metrica from '@/services/api/Metrica';
import documents from '@/services/api/Documents';
import httpClient from '@/plugins/axios';

const apiServices = {
  user,
  system,
  services,
  approval,
  businessTrip,
  cmdb,
  checklist,
  delegation,
  different,
  employeeInfo,
  employeeReplace,
  escalation,
  feedback,
  fias,
  fileStorage,
  log,
  news,
  resource,
  ticket,
  userTemplates,
  faq,
  feasts,
  database,
  articles,
  employeeSettings,
  admin,
  forms,
  controls,
  portalStyle,
  subscriptions,
  workflow,
  popular,
  survey,
  metrica,
  documents,
};

const apiServicesWithoutAuth = {
  resetPassword,
};

const api = {};

Object.entries(apiServices).forEach(([name, service]) => {
  api[name] = service(httpClient.instance);
});

Object.entries(apiServicesWithoutAuth).forEach(([name, service]) => {
  api[name] = service(httpClient.instanceWithoutAuth);
});

export default api;
