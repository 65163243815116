export default ($axios) => ({
  getSettingsUI(params = {
    source: 'otrs1',
  }) {
    return $axios
      .get('/admin/settings-ui', { params })
      .then((res) => res);
  },
  setSettingsUI(data) {
    return $axios
      .post('/admin/settings-ui', data)
      .then((res) => res);
  },
  getDefaultSettingsUI(section) {
    return $axios
      .post(`/admin/settings-ui/default?section=${section}`)
      .then((res) => res);
  },
});
