export default ($axios) => ({
  sendEmail(login) {
    return $axios
      .get('/user-change-password', {
        params: {
          customerUserLogin: login,
        },
      })
      .then((res) => res);
  },

  changePassword(password, token) {
    return $axios
      .post('/user-change-password', { password, token })
      .then((res) => res);
  },
});
