import appConfig from '@/app.config';
import { PORTAL_DEFAULT_NAME } from '@/constants/sidebar';
import generateAppConfig from '@/helpers/generateAppConfig';
import { getCreateTicketPath } from '@/helpers/pathGenerator';
import cloneDeep from 'lodash/cloneDeep';
import router from '@/router';
import {
  isMaintenancePage,
} from '@/helpers/url-path.helper';

const state = {
  isAppInitComplete: false, // загружены ли настройки приложений и настройки юзера
  config: {},
  loading: {
    app: false,
    page: false,
  },
  categories: [],
  delayedLinkAction: null, // переменная для временного хранения экшена из сайдбара
  sidebarMenu: {},
};

const mutations = {
  SET_CONFIG(state, val) {
    state.config = val;
  },
  SET_LOADING(state, { key, value }) {
    Object.assign(state.loading, { [key]: value });
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_SIDEBAR_MENU(state, payload) {
    state.sidebarMenu = payload;
  },

  SET_DELAYED_LINK_ACTION(state, payload) {
    state.delayedLinkAction = payload;
  },

  SET_IS_APP_INIT_COMPLETE(state, payload) {
    state.isAppInitComplete = payload;
  },
};

const actions = {
  async getAppSettings({ commit }) {
    try {
      const { data: appInitData } = await this.$API.system.getAppSettings();
      const config = generateAppConfig({
        ...appConfig,
        ...appInitData,
      });

      commit('SET_CONFIG', config);

      if (appInitData.categories) {
        commit('SET_CATEGORIES', appInitData.categories);
      }
      if (isMaintenancePage(window.location.pathname)) {
        router.push({ name: 'Home' });
      }
      return Promise.resolve();
    } catch (e) {
      if (e.response.status !== 401 && !isMaintenancePage(window.location.pathname)) {
        router.push({ name: 'Maintenance' });
      }
      return Promise.reject();
    }
  },

  async getUserSettings({
    commit, getters, dispatch,
  }) {
    try {
      const [{ data: userData }, { data: userSettings }] = await Promise.all([
        this.$API.user.current(),
        this.$API.user.getUserSettings(),
      ]);

      // получение текущего юзера
      commit('user/SET_USER', userData, { root: true });

      // получение индивидуальных настроек юзера
      // const sidebarMenuItems = userSettings.portalMenu;
      // const sidebarAdminMenuItems = userSettings.adminMenu;
      // const config = cloneDeep(state.config);

      // config.settingsUI.sidebarMenu.items = sidebarMenuItems;
      // config.settingsUI.sidebarMenu.adminItems = sidebarAdminMenuItems;
      // commit('SET_CONFIG', config);
      dispatch('setSidebarMenu', userSettings);

      // применение выбранного экшена пользователя в качестве первоначального действия
      const { action } = getters.firstOrderedSidebarLink;
      if (action) {
        dispatch('setDelayedLinkAction', action);
      }

      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  },

  setIsAppInitComplete({ commit }, payload = true) {
    commit('SET_IS_APP_INIT_COMPLETE', payload);
  },

  setLoading({ commit }, data) {
    commit('SET_LOADING', data);
  },

  setSidebarMenu({ commit }, payload) {
    commit('SET_SIDEBAR_MENU', payload);
  },

  async updateSidebarMenu({ state, dispatch }, payload) {
    const oldValue = cloneDeep(state.sidebarMenu);
    const { menuType, newValue } = payload;

    try {
      dispatch('setSidebarMenu', newValue);

      await this.$API.employeeSettings.updateEmployeeSettingsUI({
        // TODO: пока структура такая, если появятся другие настройки, то нужно конкатенировать объекты разных настроек
        sidebarMenu: {
          [menuType]: newValue[menuType],
        },
      });

      return Promise.resolve();
    } catch {
      dispatch('setSidebarMenu', oldValue);

      return Promise.reject();
    }
  },

  setDelayedLinkAction({ commit }, payload) {
    commit('SET_DELAYED_LINK_ACTION', payload);
  },

  clearDelayedLinkAction({ commit }) {
    commit('SET_DELAYED_LINK_ACTION', null);
  },
};

const getters = {
  postcardServices(state) {
    let services = [];
    if (state.config.postcardServices && state.config.postcardServices.length) {
      services = state.config.postcardServices.map((service) => {
        const localService = service;
        localService.title = service.name;
        localService.url = getCreateTicketPath(service.id, service.sourceSystem);
        return localService;
      });
    }
    return services;
  },

  contactInfo(state, gtrs) {
    if (gtrs.settingsUI?.contacts) {
      const items = [];
      if (gtrs.settingsUI.contacts.site) {
        items.push({
          label: gtrs.settingsUI.contacts.site,
          to: `//${gtrs.settingsUI.contacts.site}`,
          target: '_blank',
        });
      }
      if (gtrs.settingsUI.contacts.phone) {
        items.push({
          label: gtrs.settingsUI.contacts.phone,
          to: `tel:${gtrs.settingsUI.contacts.phone.replace(/[^0-9]/g, '')}`,
          target: '_blank',
        });
      }
      if (gtrs.settingsUI.contacts.email) {
        items.push({
          label: gtrs.settingsUI.contacts.email,
          to: `mailto:${gtrs.settingsUI.contacts.email}`,
          target: '_blank',
        });
      }
      return items;
    }
    return [];
  },
  portals: (state, gtrs) => gtrs.settingsUI?.esmps || [],
  portalLogo: (state, gtrs) => gtrs.settingsUI?.logo || {},
  portalTitle: (state, gtrs) => gtrs.settingsUI?.titlePortal || PORTAL_DEFAULT_NAME,
  portalFavicon: (state, gtrs) => gtrs.settingsUI?.faviconImageUrl || '/favicon.ico',
  faqArticleImageUrl: (state, gtrs) => gtrs.settingsUI?.faqArticleImageUrl,
  faqCategoryImageUrl: (state, gtrs) => gtrs.settingsUI?.faqCategoryImageUrl,

  currentPortal: (state, gtrs) => {
    const portals = gtrs.settingsUI?.esmps;
    if (portals?.length) {
      return portals.find((portal) => portal.isCurrentPortal)
        || portals.find((portal) => !portal.isDisabled)
        || portals[0];
    }
    return null;
  },

  // верхний пункт меню в сайдбаре
  firstOrderedSidebarLink(state) {
    if (!state?.sidebarMenu?.portalMenu[0]) return {};

    const link = state.sidebarMenu.portalMenu[0];
    const { action, url } = link;

    return {
      link,
      action,
      url,
    };
  },

  settingsUI(state) {
    return state.config?.settingsUI;
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
