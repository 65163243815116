export default ($axios) => ({
  // Получение списка адресов
  getLocations(params = {
    searchType: undefined,
    page: undefined,
    size: undefined,
  }) {
    return $axios
      .get('/fias/search', { params })
      .then((res) => res);
  },
});
