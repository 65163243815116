export default ($axios) => ({
  // Получение доступных пользователю услуг
  getList(data = null) {
    return $axios
      .post('/services', data)
      .then((res) => res);
  },
  getDescription(id, params = { source: undefined }) {
    return $axios
      .get(`/services/${id}`, { params })
      .then((res) => res);
  },
  // Получение дерева услуг
  getListByStructure(data = null) {
    return $axios
      .post('/services/structure', data)
      .then((res) => res);
  },
  // Получение дерева услуг по id,
  // TODO этот ендпоинт требует как минимум пустой объект (null/undefined не катит)
  getListByStructureId(id, data = {}) {
    return $axios
      .post(`/services/${id}/structure`, data)
      .then((res) => res);
  },
  checkUserAccess(params = {}) {
    return $axios
      .get('/services/check-user-access', { params })
      .then((res) => res);
  },
  getFavorites() {
    return $axios
      .get('/services/favorites')
      .then((res) => res);
  },

  /**
   * Добавление услуги в избранное
   * @param serviceId {string} - id услуги
   * @param serviceName {string|null} - имя услуги
   * @param sourceSystem {string} - мастер система
   * @return {Promise<object>}
   */
  addFavorites({ serviceId, serviceName = null, sourceSystem }) {
    if (!serviceId || !sourceSystem) {
      throw new Error('Параметры serviceId и sourceSystem обязательны!');
    }

    return $axios
      .post('/services/favorites', { id: serviceId, name: serviceName, sourceSystem })
      .then((res) => res);
  },

  /**
   * Удаление услуги из избранного
   * @param serviceId {string} - id услуги
   * @param serviceName {string|null} - имя услуги
   * @param sourceSystem {string} - мастер система
   * @return {Promise<void>}
   */
  deleteFavorites({ serviceId, serviceName = null, sourceSystem }) {
    if (!serviceId || !sourceSystem) {
      throw new Error('Параметры serviceId и sourceSystem обязательны!');
    }

    return $axios
      .delete(`/services/favorites/${serviceId}`, { params: { serviceName, source: sourceSystem } })
      .then((res) => res);
  },

  updateFavorites(data = null) {
    return $axios
      .patch('/services/favorites', data)
      .then((res) => res);
  },
  getPopular(params = {
    count: undefined,
    regionId: undefined,
  }) {
    return $axios
      .get('/services/popular', { params })
      .then((res) => res);
  },
  // Поиск в имени, описании и ключевых словах всех услуг.
  // Учитывается неверная раскладка, неверный язык и неверные окончания слов.
  // Если передано поле userInfoByMasterSystems, то ищутся услуги для другого сотрудника.
  // Если ищутся доступные услуги "для себя", то userInfoByMasterSystems не должно быть передано
  smartSearch(data) {
    return $axios({
      method: data.method,
      url: data.url,
      data: data.data,
      params: data.params,
    })
      .then((res) => res);
  },

  /**
   * Поиск в имени, описании и ключевых словах всех услуг. Учитывается неверная
   * раскладка, неверный язык и неверные окончания слов.
   *
   * @link http://dev-tbkrv.ks.rt.ru:8080/swagger-ui.html#/Service%20operations/smartServiceSearchUsingPOST
   * @link https://ci-01-otrs.rtk-dc.ru/helpme/helpme-docs/-/wikis/Поиск-по-услугам-(основной)
   *
   * @param query {string} - поисковый запрос
   * @param sourceSystem {string} - мастер система, обычно otrs1
   * @param allowedOnly {boolean} - только доступные пользователю услуги
   * @param userInfoByMasterSystems {object} - поиск услуги для другого сотрудника
   *
   * @returns {promise}
   */
  smartSearchExact(query, {
    sourceSystem,
    allowedOnly = true,
    userInfoByMasterSystems,
  }) {
    if (!query) {
      throw new Error('Поле "query" обязательно для запроса "api/services/smart-search"!');
    }

    const params = {
      query, sourceSystem, allowedOnly, userInfoByMasterSystems,
    };

    return $axios
      .post('/services/smart-search', params)
      .then((res) => res.data);
  },
  simpleSearch(query) {
    return $axios
      .get('/services/simple-search', {
        params: {
          query,
          source: 'otrs1',
        },
      })
      .then((res) => res);
  },
  getServiceInfo(id) {
    return $axios
      .get(`/services/${id}/info`, {
        params: {
          source: 'otrs1',
        },
      })
      .then((res) => res);
  },
});
