import { DEFAULT_SOURCE_SYSTEM } from '@/constants/global';

export default ($axios) => ({
  getLocationNodes(params = {
    source: DEFAULT_SOURCE_SYSTEM,
  }) {
    return $axios
      .get('/admin/location/nodes', { params })
      .then((res) => res);
  },
  getBranches(nodeId, source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .get('/admin/location/branches', {
        params: {
          nodeId,
          source,
        },
      })
      .then((res) => res);
  },
  getAddressInBranch(nodeId, branchId, query = '', source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .get('/admin/location/addresses-in-branch', {
        params: {
          nodeId,
          branchId,
          source,
          query,
        },
      })
      .then((res) => res);
  },
  getAddressInNode(nodeId, query = '', source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .get('/admin/location/addresses-in-node', {
        params: {
          nodeId,
          source,
          query,
        },
      })
      .then((res) => res);
  },
  getAddress(query = '', source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .get('/admin/location/addresses', {
        params: {
          source,
          query,
        },
      })
      .then((res) => res);
  },
  getPriorities(source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .get(`/admin/priorities?source=${source}`)
      .then((res) => res);
  },
  updatePriority(id, data, source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .patch(`/admin/priorities/${id}?source=${source}`, data)
      .then((res) => res);
  },
  getTicketTypes(source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .get(`/admin/ticket-types?source=${source}`)
      .then((res) => res);
  },
  getImpactLevels(source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .get(`/admin/itsm-impact-levels?source=${source}`)
      .then((res) => res);
  },

  /**
   * Получение всех типов статусов заявок
   *
   * {@link https://confluence.rt.ru/pages/viewpage.action?pageId=496213845|Doc}
   * @param isDefault {boolean} - загрузить ли доступные для формы статусы (по умолчанию)
   * @param source {string} - мастер-система
   * @returns {[{}]} - массив статусов
   */
  getStatuses(isDefault = false, source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .get('/admin/states', { params: { source, isDefault } })
      .then((res) => res);
  },
  /**
   * Обновление статуса
   *
   * {@link https://confluence.rt.ru/pages/viewpage.action?pageId=496214551|Doc}
   * @param id {string} - id статуса
   * @param data {object} - объект с перезаписываемыми свойсвами и otrsId статуса
   * @param source {string} - мастер-система
   * @returns {*}
   */
  setStatus(id, data, source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .patch(`/admin/states/${id}?source=${source}`, data)
      .then((res) => res);
  },

  /**
   * Экспорт формы
   *
   * @param formId {string|number} - id формы для эспорта
   * @param source {string} - мастер-система
   * @returns {object} - форма
   */
  exportForm(formId, source = DEFAULT_SOURCE_SYSTEM) {
    return $axios
      .get(`/admin/form/export/${formId}`, { params: { source } })
      .then((res) => res);
  },

  /**
   * Импорт формы из json-файла
   *
   * @see https://confluence.rt.ru/pages/viewpage.action?pageId=545553620
   * @param formData {formData} - FormData формы, внутри fileControlsForm, metaFormService
   * @returns {string} - url вновь созданной формы
   */
  importForm(formData) {
    return $axios.post('admin/forms/import-from-other-server', formData);
  },
});
