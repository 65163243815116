import Vue from 'vue';
import { BAND_TYPES } from '@/constants/formWorkflow';

export const state = {
  dependencies: [],
};

export const mutations = {
  INIT_DEPENDENCY(state, formDependencies) {
    state.dependencies = [];
    if (formDependencies && formDependencies.length) {
      formDependencies.forEach((formDependency) => {
        const dependency = {
          id: formDependency.id,
          bandType: BAND_TYPES[formDependency.flowType],
          flowType: formDependency.flowType,
          flowName: formDependency.flowName,
          conditions: [],
        };
        if (formDependency.conditions && formDependency.conditions.length) {
          formDependency.conditions.forEach((formDependencyCondition) => {
            const condition = {
              id: formDependencyCondition.id,
              listValueId: formDependencyCondition.listValueId,
              listValueTechId: formDependencyCondition.listValueId,
              controlId: formDependencyCondition.formItemId,
              type: formDependencyCondition.operationType,
            };
            dependency.conditions.push(condition);
          });
        }
        state.dependencies.push(dependency);
      });
    }
  },
  ADD_DEPENDENCY(state, dependency) {
    state.dependencies.push(dependency);
  },
  REMOVE_DEPENDENCY(state, dependencyIndex) {
    if (state.dependencies[dependencyIndex]) {
      state.dependencies.splice(dependencyIndex, 1);
    }
  },
  UPDATE_DEPENDENCY_BAND_TYPE(state, { bandType, flowType, dependencyIndex }) {
    const dependency = state.dependencies[dependencyIndex];
    Vue.set(dependency, 'bandType', bandType);
    Vue.set(dependency, 'flowType', flowType);
    Vue.set(dependency, 'flowName', null);
  },
  UPDATE_DEPENDENCY_FlOW_NAME(state, { flowName, dependencyIndex }) {
    const dependency = state.dependencies[dependencyIndex];
    Vue.set(dependency, 'flowName', flowName);
  },
  ADD_CONDITION(state, { dependencyIndex, condition }) {
    const dependency = state.dependencies[dependencyIndex];
    dependency.conditions.push(condition);
  },
  REMOVE_CONDITION(state, { dependencyIndex, conditionIndex }) {
    const dependency = state.dependencies[dependencyIndex];
    if (conditionIndex > 0 && dependency.conditions[conditionIndex]) {
      // Если это последнее условие в зависимости (или второе из двух) - обнуляем тип у предыдущего условия,
      // иначе оставляем то, которое было до этого.
      if (conditionIndex === 1 || !dependency.conditions[conditionIndex + 1]) {
        Vue.set(dependency.conditions[conditionIndex - 1], 'type', null);
      }
      dependency.conditions.splice(conditionIndex, 1);
    }
  },
  UPDATE_CONDITION_TYPE(
    state,
    { conditionType, dependencyIndex, conditionIndex },
  ) {
    const dependency = state.dependencies[dependencyIndex];
    Vue.set(dependency.conditions[conditionIndex], 'type', conditionType);
  },
  UPDATE_CONDITION_CONTROL(
    state,
    { controlId, dependencyIndex, conditionIndex },
  ) {
    const dependency = state.dependencies[dependencyIndex];
    Vue.set(dependency.conditions[conditionIndex], 'controlId', controlId);
    Vue.set(dependency.conditions[conditionIndex], 'listValueTechId', null);
    Vue.set(dependency.conditions[conditionIndex], 'listValueId', null);
  },
  UPDATE_CONDITION_VALUES(state, { values, dependencyIndex, conditionIndex }) {
    const dependency = state.dependencies[dependencyIndex];
    Vue.set(dependency.conditions[conditionIndex], 'values', values);
  },
  UPDATE_CONDITION_LIST_VALUE(
    state,
    { listValueTechId, dependencyIndex, conditionIndex },
  ) {
    const dependency = state.dependencies[dependencyIndex];
    Vue.set(
      dependency.conditions[conditionIndex],
      'listValueTechId',
      listValueTechId,
    );
    const listValue = dependency.conditions[conditionIndex].values.find(
      (value) => value.techId === listValueTechId,
    );
    if (!listValueTechId) {
      Vue.set(dependency.conditions[conditionIndex], 'listValueId', null);
    } else if (listValue) {
      Vue.set(
        dependency.conditions[conditionIndex],
        'listValueId',
        listValue.id,
      );
    }
  },
  UPDATE_DEPENDENCIES(state, data) {
    state.dependencies = data;
  },
};

export const actions = {
  initFormDependencies({ commit }, formDependencies) {
    commit('INIT_DEPENDENCY', formDependencies);
  },
  addDependencyAction({ commit }, dependency) {
    commit('ADD_DEPENDENCY', dependency);
  },
  removeDependencyAction({ commit }, dependencyIndex) {
    commit('REMOVE_DEPENDENCY', dependencyIndex);
  },
  updateDependencyBandTypeAction(
    { commit },
    { bandType, flowType, dependencyIndex },
  ) {
    commit('UPDATE_DEPENDENCY_BAND_TYPE', {
      bandType,
      flowType,
      dependencyIndex,
    });
  },
  updateDependencyFlowNameAction({ commit }, { flowName, dependencyIndex }) {
    commit('UPDATE_DEPENDENCY_FlOW_NAME', { flowName, dependencyIndex });
  },
  addConditionAction({ commit }, { dependencyIndex, condition }) {
    commit('ADD_CONDITION', { dependencyIndex, condition });
  },
  removeConditionAction({ commit }, { dependencyIndex, conditionIndex }) {
    commit('REMOVE_CONDITION', { dependencyIndex, conditionIndex });
  },
  updateConditionTypeAction(
    { commit },
    { conditionType, dependencyIndex, conditionIndex },
  ) {
    commit('UPDATE_CONDITION_TYPE', {
      conditionType,
      dependencyIndex,
      conditionIndex,
    });
  },
  updateConditionControlAction(
    { commit },
    { controlId, dependencyIndex, conditionIndex },
  ) {
    commit('UPDATE_CONDITION_CONTROL', {
      controlId,
      dependencyIndex,
      conditionIndex,
    });
  },
  updateConditionValuesAction(
    { commit },
    { values, dependencyIndex, conditionIndex },
  ) {
    commit('UPDATE_CONDITION_VALUES', {
      values,
      dependencyIndex,
      conditionIndex,
    });
  },
  updateConditionListValueAction(
    { commit },
    { listValueTechId, dependencyIndex, conditionIndex },
  ) {
    commit('UPDATE_CONDITION_LIST_VALUE', {
      listValueTechId,
      dependencyIndex,
      conditionIndex,
    });
  },
  updateDependencies({ commit }, data) {
    commit('UPDATE_DEPENDENCIES', data);
  },
};

const dependencies = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default dependencies;
