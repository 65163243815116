/**
 * Кол-во статей на страницу
 * @link https://ci-01-otrs.rtk-dc.ru/helpme/helpme-docs/-/wikis/FAQ/Получение-содержимого-категории
 * @type {number}
 */
export const FAQ_ARTICLES_COUNT_PER_PAGE = 8;

export const test = 123;

export const FAQ_SORT_TYPES = {
  createdUp: {
    code: 'createdUp',
    name: 'по дате создания от старых к новым',
  },
  createdDown: {
    code: 'createdDown',
    name: 'по дате создания от новых к старым',
  },
  changedUp: {
    code: 'changedUp',
    name: 'по дате изменения от старых к новым',
  },
  changedDown: {
    code: 'changedDown',
    name: 'по дате изменения от новых к старым',
  },
};
