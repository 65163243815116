export default ($axios) => ({
  // Получение списка статьей
  // TODO: Endpoint еще не реализован
  getArticles(params = {
    ignoreSubscriptions: undefined,
    state: undefined,
    isMain: undefined,
    isPublic: undefined,
    serviceId: undefined,
  }) {
    return $axios
      .get('/articles', { params })
      .then((res) => res);
  },
  // Получение статьи
  // TODO: Endpoint еще не реализован
  getArticlesItem(id) {
    return $axios
      .get(`/articles/${id}`)
      .then((res) => res);
  },
});
