import axios from 'axios';

const { CancelToken } = axios;
let cancelSearchGroupToken;

export default ($axios) => ({
  // Поиск ресурсов в группе ресурсов
  searchGroup(params = {
    customerLogin: undefined,
    resourceGroupName: undefined,
    searchResourceQuery: undefined,
    source: undefined,
  }) {
    return $axios
      .get('/resource/group-search', {
        params,
        cancelToken: new CancelToken((c) => {
          cancelSearchGroupToken = c;
        }),
      }).then((res) => res);
  },
  cancelSearchGroup() {
    if (cancelSearchGroupToken) cancelSearchGroupToken();
  },
  // Получить список ролей по группе
  getRolesByGroup(params = {
    groupName: undefined,
    source: undefined,
  }) {
    return $axios
      .get('/resource/group/roles', { params })
      .then((res) => res);
  },
  getResources(source = 'otrs1') {
    return $axios
      .get(`/resource/groups?source=${source}`)
      .then((res) => res);
  },
  getRoles(groupName, source = 'otrs1') {
    return $axios
      .get('/resource/group/roles', {
        params: {
          source,
          groupName,
        },
      })
      .then((res) => res);
  },
});
