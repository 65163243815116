/*
  popularServicesAvailable - на текущий момент не используется, раньше был функционал
  отображения списка популярных услуг. В будущем планируется использовать.
  userPhotoFromCorpPortalBaseUrl - не используется, будет удалено из запроса
 */
// TODO: нужно не только скрывать кнопки делегирования и тд, но и удалять роуты, а возможно и код из билда

/**
 * Массив разрешенных ключей настроек в конфиге
 * @type {string[]}
 */
const allowedConfigKeys = [
  'categories',
  'settingsUI',
  'postcardServices',
  'ticketWatchersAvailable',
  'userInfoAvailable',
  'clientUserSearchInfoAvailable',
  'isChatBotRegistrationButtonAvailable',
  'isBusinessTripAvailable',
  'isDelegationsAvailable',
  'isRequestFromAvailable',
  'loginPageCaption',
  'isResetPasswordAvailable',

  // TODO: временные, удалить, когда будут приходить с бека
  'auth',
  'site',
  'email',
  'phone',
];

/**
 * Маппинг значений с сервера под код клиента
 * isChatBotRegistrationButtonAvailable - доступность кнопка регистрации в чат-боте
 * isBusinessTripAvailable - доступность командировок
 * isDelegationsAvailable - доступность делегирования
 * isRequestFromAvailable - доступность заявки от имени
 * isCreateGroupTicketAvailable - доступность групповой заявки
 */
const configKeysMap = {
  registrationChatbotAvailable: 'isChatBotRegistrationButtonAvailable',
  businessTripAvailable: 'isBusinessTripAvailable',
  delegationsAvailable: 'isDelegationsAvailable',
  createTicketFromOtherPersonAvailable: 'isRequestFromAvailable',
  createGroupTicketAvailable: 'isCreateGroupTicketAvailable',
  resetPasswordAvailable: 'isResetPasswordAvailable',
};

/**
 * Маппинг конфига приложения
 * @param config {{}} - конфиг
 * @return {{}} - готовый конфиг приложения
 */
const generateAppConfig = (config = {}) => {
  const mappedConfig = {};

  Object.keys(config).forEach((key) => {
    if (configKeysMap[key]) {
      mappedConfig[configKeysMap[key]] = config[key];
    } else if (allowedConfigKeys.includes(key)) {
      mappedConfig[key] = config[key];
    }
  });

  return mappedConfig;
};

export default generateAppConfig;
