export default ($axios) => ({
  /**
   * Отправка id лога поиска при переходе
   * на страницу создания заявки из поиска
   * @param searchLogId {number} - id лога поиска
   * @param serviceId {string} - id услуги
   * @param sourceSystem {string} - мастер-система
   * @return {*}
   */
  sendSearchLogIdOnCreateTicket({ searchLogId, serviceId, sourceSystem }) {
    return $axios
      .post('/logs/add-service', { searchLogId, serviceId, sourceSystem })
      .then((res) => res);
  },
});
