export default ($axios) => ({
  getSubscriptions(formId, source = 'otrs1') {
    return $axios
      .get(`/admin/forms/${formId}/subscription-structure?source=${source}`)
      .then((res) => res);
  },
  importOtrsSubscr(subscriptionIds, serviceId, source = 'otrs1') {
    return $axios
      .post(`/admin/ms-subscriptions/convert-to-form-subscriptions?source=${source}`, {
        subscriptionIds,
        serviceId,
      })
      .then((res) => res);
  },
  getOtrSubscr(serviceId, source = 'otrs1') {
    return $axios
      .get(`/admin/ms-subscriptions?serviceId=${serviceId}&source=${source}`)
      .then((res) => res);
  },
});
