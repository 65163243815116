<template>
  <div class="old-browser-info">
    <esmp-icon name="info2" />
    <span>Ваш браузер устарел. Просьба использовать другие браузеры в работе: Яндекс.Браузер или Google Chrome.</span>
  </div>
</template>

<script>
export default {
  name: 'OldBrowserInfo',
};
</script>

<style lang="scss">
.old-browser-info {
  padding: calc(#{$gap} / 2);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
