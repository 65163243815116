export default ($axios) => ({
  getCheckList(id, params = {
    contactId: undefined,
    customerId: undefined,
    customerLogin: undefined,
    serviceId: undefined,
    source: undefined,
    templateId: undefined,
  }) {
    return $axios
      .get(`/checklists/${id}`, { params })
      .then((res) => res);
  },
  checkGroupTaskAccess(params = {
    contactId: undefined,
    customerId: undefined,
    customerLogin: undefined,
    serviceId: undefined,
    source: undefined,
    formVersionId: undefined,
  }) {
    return $axios
      .get('/checklists/check-group-task-access', { params })
      .then((res) => res);
  },
  // Проверка входит ли ip адрес в диапазон используемых адресов
  validateIpAddressInfo(data = null) {
    return $axios
      .post('/checklists/validate-ip-address-info', data)
      .then((res) => res);
  },
  getCheckListFromExistedTicket(params = {
    ticketId: undefined,
    customerLogin: undefined,
    source: undefined,
  }) {
    return $axios
      .get('/checklists/from-existed-ticket', { params })
      .then((res) => res);
  },
  getCheckListByFormId(serviceId, params = {
    formId: undefined,
    source: undefined,
  }) {
    return $axios
      .get(`/admin/checklists/${serviceId}`, { params })
      .then((res) => res);
  },
  // Получение опросного листа для документа
  getDocumentCheckList(documentId, params = {
    id: undefined,
    source: undefined,
  }) {
    return $axios
      .get(`/checklists/documents/${documentId}`, { params })
      .then((res) => res);
  },
});
