const state = {
  breadcrumbs: [],
};

const getters = {};

const mutations = {
  SET_BREADCRUMBS(state, payload) {
    state.breadcrumbs = payload;
  },

  CLEAR_BREADCRUMBS(state) {
    state.breadcrumbs = [];
  },
};

const actions = {
  setBreadcrumbs({ commit }, payload) {
    commit('SET_BREADCRUMBS', payload);
  },

  clearBreadcrumbs({ commit }) {
    commit('CLEAR_BREADCRUMBS');
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
