export const businessTrip = {
  tabs: {
    COMMON_DATA: 'Общие данные',
    EXPENSES: 'Командировочные расходы',
  },
  dayDuration: {
    ONE: 'день',
    FEW: 'дня',
    MANY: 'дней',
    DEFAULT: 'XX',
  },
  rub: {
    ONE: 'рубль',
    FEW: 'рубля',
    MANY: 'рублей',
  },
  transitWays: {
    PLANE: 'plane',
    TRAIN: 'train',
    BUS: 'bus',
  },
  TICKET_FORM: {
    plane: 'Авиа',
    train: 'Ж/д',
    bus: 'Автобус',
  },
  TICKET_PRICE: {
    plane: 'avia',
    train: 'railway',
    bus: 'bus',
  },
  tripClass: {
    BUSINESS: 'Бизнес',
    ECONOMY: 'Эконом',
  },
  DAY_PRICE: 250,
  SERVICE_CHARGE: {
    hotel: 160.01,
    plane: 240.01,
    train: 121.01,
    bus: 0,
  },
  DESTINATION: {
    expenses: 'expenses',
    accommodation: 'accommodation',
    transitStart: 'transitStart',
    transitEnd: 'transitEnd',
    cityAbbrev: 'г',
  },
};

export const BUSINESS_TRIP_STATE = {
  approval: 'canCancelApproval',
  editable: 'canEditFields',
  editableWithApproval: 'canEditFieldsPending',
  uncategorized: 'unrecognized',
  isHidden: 'hideActions',
};

/* eslint-disable quote-props */
export const CHECKLIST_DATA = {
  type: {
    label: 'Тип',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'common',
  },
  userPosition: {
    label: 'Должность',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'user',
  },
  userBlock: {
    label: 'Блок',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'user',
  },
  userOrganization: {
    label: 'Отдел',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'user',
  },
  userDepartment: {
    label: 'Департамент',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'user',
  },
  userEmail: {
    label: 'Электронная почта',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'user',
  },
  freeFundsAmount: {
    label: 'Сумма свободных фондов',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'common',
  },
  initiatorEmployeeNumber: {
    label: 'Создатель заявки',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'common',
  },
  employeeNumber: {
    label: 'Сотрудник',
    classType: 'select',
    hidden: false,
    readOnly: true,
    group: 'user',
  },
  assignmentId: {
    label: 'Идентификатор назначения',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'user',
  },
  budgetUnit: {
    label: 'Бюджетная единица',
    classType: 'input',
    hidden: false,
    readOnly: true,
    group: 'user',
  },
  cfoConsumer: {
    label: 'ЦФО-потребителя',
    classType: 'select',
    hidden: false,
    readOnly: true,
    group: 'common',
  },
  expenseItem: {
    label: 'Статья',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'common',
  },
  projectCode: {
    label: 'Инвестиционный проект',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'common',
  },
  businessProcessCode: {
    label: 'Бизнес-процесс',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'common',
  },
  codeProgram: {
    label: 'Код программы',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'common',
  },
  mobilePhone: {
    label: 'Мобильный телефон',
    classType: 'input',
    hidden: false,
    readOnly: false,
    group: 'user',
  },
  aim: {
    label: 'Цель командировки',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'common',
  },
  aimDescription: {
    label: 'Детали командировки',
    classType: 'input',
    hidden: false,
    readOnly: false,
    group: 'common',
  },
  chiefEmployeeNumber: {
    label: 'Руководитель',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'user',
  },
  destinationOrganization: {
    label: 'Место назначения',
    classType: 'input',
    hidden: false,
    readOnly: true,
    group: 'info',
  },
  startDate: {
    label: 'Дата начала командировки',
    classType: 'date',
    hidden: false,
    readOnly: false,
    group: 'info',
  },
  endDate: {
    label: 'Дата окончания командировки',
    classType: 'date',
    hidden: false,
    readOnly: false,
    group: 'info',
  },
  avansAmount: {
    label: 'Сумма аванса',
    classType: 'text',
    hidden: false,
    readOnly: false,
    group: 'common',
  },
  avansAmountComment: {
    label: 'Комментарий к авансу',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'common',
  },
  isNeedHelpFindingTicket: {
    label: 'Нужна помощь в подборе билета',
    classType: 'input',
    hidden: false,
    readOnly: true,
    group: 'transit',
  },
  groupBooking: {
    label: 'Групповое бронирование',
    classType: 'input',
    hidden: false,
    readOnly: true,
    group: 'accommodation',
  },
  tripSum: {
    label: 'Общая стоимость командировки',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'info',
  },
  destinationPlaceTerritory: {
    label: 'Территориальная зона места назначения',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'info',
  },
  destinationPlaceRegion: {
    label: 'Регион места назначения',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'info',
  },
  destinationPlaceArea: {
    label: 'Район места назначения',
    classType: 'text',
    hidden: true,
    readOnly: false,
    group: 'info',
  },
  destinationPlaceTown: {
    label: 'Город места назначения',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'info',
  },
  destinationPlaceSettlement: {
    label: 'Посёлок места назначения',
    classType: 'text',
    hidden: false,
    readOnly: false,
    group: 'info',
  },
  place: {
    label: 'Место назначения',
    checkListLabel: 'Дополнительные места назначения',
    showSubtitleOnce: true,
    hideSubtitleNumber: true,
    classType: 'text',
    hidden: false,
    readOnly: false,
    group: 'info',
  },
  hotels: {
    label: 'Отели',
    classType: 'text',
    hidden: false,
    readOnly: false,
    group: 'accommodation',
  },
  tickets: {
    label: 'Билеты',
    classType: 'text',
    hidden: false,
    readOnly: false,
    group: 'transit',
  },
  ticketForm: {
    label: 'Тип проезда',
    classType: 'radio',
    hidden: false,
    readOnly: false,
    group: 'transit',
  },
  territoryZone: {
    label: 'Территориальная зона',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'transit',
  },
  tripClass: {
    label: 'Класс',
    classType: 'checkbox',
    hidden: false,
    readOnly: false,
    group: 'transit',
  },
  ticketStartDate: {
    label: 'Когда',
    classType: 'date',
    hidden: false,
    readOnly: false,
    group: 'transit',
  },
  tripNumber: {
    label: 'Номер рейса/поезда',
    classType: 'input',
    hidden: false,
    readOnly: false,
    group: 'transit',
  },
  departureFrom: {
    label: 'Откуда',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'transit',
  },
  departureTo: {
    label: 'Куда',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'transit',
  },
  taxService: {
    label: 'Сервисный сбор',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'transit',
    values: {
      '0': 'Нет',
      '1': 'Да',
    },
  },
  timeOfDay: {
    label: 'Отправление',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'transit',
  },
  city: {
    label: 'Место назначения',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'accommodation',
  },
  residenceFrom: {
    label: 'Заезд',
    classType: 'date',
    hidden: false,
    readOnly: false,
    group: 'accommodation',
  },
  residenceTo: {
    label: 'Выезд',
    classType: 'date',
    hidden: false,
    readOnly: false,
    group: 'accommodation',
  },
  name: {
    // hotel name
    label: 'Отель',
    classType: 'select',
    hidden: false,
    readOnly: false,
    group: 'accommodation',
  },
  category: {
    label: 'Категория',
    classType: 'text',
    hidden: false,
    readOnly: false,
    group: 'accommodation',
  },
  earlyArrive: {
    label: 'Ранее прибытие',
    classType: 'text',
    hidden: false,
    readOnly: false,
    group: 'accommodation',
    values: {
      '0': 'Нет',
      '1': 'Да',
    },
  },
  lateDeparture: {
    label: 'Поздний вылет/отправление',
    classType: 'text',
    hidden: false,
    readOnly: false,
    group: 'accommodation',
    values: {
      '0': 'Нет',
      '1': 'Да',
    },
  },
  requireMatch: {
    label: 'Требуется подбор',
    classType: 'checkbox',
    hidden: false,
    readOnly: true,
    group: 'accommodation',
    values: {
      '0': 'Нет',
      '1': 'Да',
    },
  },
  requisitionNumber: {
    label: 'Номер заявки в R12',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'common',
  },
  totalSummByR12: {
    label: 'Лимит командировки',
    classType: 'text',
    hidden: false,
    readOnly: true,
    group: 'common',
  },
  isVIPGrade: {
    label: 'VIP Грейд',
    classType: 'checkbox',
    hidden: true,
    readOnly: false,
  },
};

export const CHECKLIST_GROUP = {
  common: 'Общие данные',
  user: 'Информация о сотруднике',
  info: 'Общие сведения',
  transit: 'Проезд',
  accommodation: 'Проживание',
};

export const REFERENCE = {
  expensesCity: {
    id: null,
    region: null,
    area: null, // district
    city: null, // town
    settlement: null, // locality
  },

  expenses: {
    cities: [],
    organization: {
      id: 'ПАО «Ростелеком»',
      name: 'ПАО «Ростелеком»',
    },
    otherOrganization: '',
    commonDayStart: null,
    commonDayEnd: null,
    isSelfBooking: false,
    isNeedHelp: false,
    groupBooking: false,
    avansAmount: '',
    avansAmountComment: null,
  },

  transit: {
    id: null,
    transitWay: 'plane',

    zone: null,

    startPoint: null,
    endPoint: null,

    day: null,

    planeTripNumber: '',
    trainTripNumber: '',
    busTripNumber: '',

    timeOfDay: null,

    isBusinessClass: false,
    isFirstClass: false,
  },
};

export const ARTICLES = {
  education: {
    targetName: 'Обучение',
    articleId: 'E105010102',
  },
  jobAssignment: {
    targetName: '',
    articleId: 'E105010101',
  },
};

export const RTK_NAMES = ['ПАО «Ростелеком»', 'Ростелеком'];

// eslint-disable-next-line no-useless-escape,max-len
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default businessTrip;
