export default ($axios) => ({
  // Получение списка шаблонов документов
  getDocuments(params = {
    page: undefined,
    size: undefined,
    sortBy: undefined,
    query: undefined,
  }) {
    return $axios
      .get('/admin/documents', { params })
      .then((res) => res);
  },
  // Получение шаблона документа
  getDocument(id) {
    return $axios
      .get(`/admin/documents/${id}`)
      .then((res) => res);
  },
  // Получение доступных контролов для шаблона документов
  getControls() {
    return $axios
      .get('/admin/documents/controls')
      .then((res) => res);
  },
  // Создание шаблона документов
  createDocument(dto) {
    return $axios
      .post('/admin/documents', dto)
      .then((res) => res);
  },
  // Редактирование шаблона документа
  updateDocument(dto) {
    return $axios
      .put('/admin/documents', dto)
      .then((res) => res);
  },
  // Удаление шаблона документа
  removeDocument(id) {
    return $axios
      .delete(`/admin/documents/${id}`)
      .then((res) => res);
  },
  // Получение списка версий шаблона документа
  getFormVersions(id) {
    return $axios
      .get(`/admin/documents/${id}/versions`)
      .then((res) => res);
  },
  // Удаление версии шаблона документа
  deleteFormVersion(id, versionId) {
    return $axios
      .delete(`/admin/documents/${id}/versions/${versionId}`)
      .then((res) => res);
  },
  // Активация версии шаблона документа
  updateFormActiveVersion(id, versionId) {
    return $axios
      .patch(`/admin/documents/${id}/versions/${versionId}`)
      .then((res) => res);
  },
  // Сравнение двух версий шаблона документа
  getDiffFormVersions(formId, originalFormVersionId, changedFormVersionId) {
    return $axios
      .get(`/admin/documents/${formId}/getDiff`, { params: { originalFormVersionId, changedFormVersionId } })
      .then((res) => res);
  },
});
