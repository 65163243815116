export default ($axios) => ({
  // Загрузка файла в файловое хранилище
  upload(data = null) {
    return $axios
      .post('/files', data)
      .then((res) => res);
  },
  remove(id) {
    return $axios
      .delete(`/files/${id}`)
      .then((res) => res);
  },
});
