import localStorageDb from '@/utils/localStorageDB';

const state = () => ({
  isCollapsedMenu: localStorageDb.get('isCollapsedMenu') || false,
});

const mutations = {
  SET_IS_COLLAPSED_MENU(state, isCollapsedMenu) {
    state.isCollapsedMenu = isCollapsedMenu;
    localStorageDb.put('isCollapsedMenu', isCollapsedMenu);
  },
};

const actions = {
  collapseMenu({ commit }, isCollapsedMenu) {
    commit('SET_IS_COLLAPSED_MENU', isCollapsedMenu);
  },
};

const sidebar = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default sidebar;
