import Vue from 'vue';
import VueRouter from 'vue-router';
import localStorageDb from '@/utils/localStorageDB';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  router.lastRoute = from;
  if (to.path.startsWith('/admin') && !localStorageDb.get('isAdmin')) {
    next({ name: 'Login' });
  }
  next();
});

/**
 * Обновление meta-составляющей роута
 * @param route - инстанс роута, в котором нужно изменить meta-свойство
 * @param key {string} - имя изменяемого свойства
 * @param value {*} - новое значение изменяемого свойства
 */
router.setMetaOption = (route, key, value) => {
  // eslint-disable-next-line no-param-reassign
  route.meta[key] = value;

  router.replace({ query: { t: '.' } });
  router.replace({ query: { t: undefined } });
};

export default router;
