// eslint-disable-next-line func-names
(function () {
  let lastTime = 0;
  const vendors = ['webkit', 'moz'];

  // eslint-disable-next-line no-plusplus
  for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[`${vendors[x] }RequestAnimationFrame`];
    window.cancelAnimationFrame = window[`${vendors[x]}CancelAnimationFrame`]
      || window[`${vendors[x]}CancelRequestAnimationFrame`];
  }

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = (callback) => {
      const currTime = new Date().getTime();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));
      const id = window.setTimeout(() => { callback(currTime + timeToCall); },
        timeToCall);
      lastTime = currTime + timeToCall;

      return id;
    };
  }

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = (id) => clearTimeout(id);
  }
}());

const doubleRequestAnimationFrame = (callback) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(callback);
  });
};

const forceNextTick = (callback) => {
  if (callback && typeof callback === 'function') {
    return doubleRequestAnimationFrame(callback);
  }
  return new Promise((resolve) => {
    doubleRequestAnimationFrame(resolve);
  });
};

const VueForceNextTick = {
  install(Vue) {
    Vue.$forceNextTick = forceNextTick;
    Vue.prototype.$forceNextTick = forceNextTick;
  },
};

export default VueForceNextTick;
