export default [
  {
    path: '/',
    name: 'Home',
    meta: {
      // layout: 'LayoutDefault', - используется по умолчанию
      title: 'Главная',
    },
    component: () => import(/* webpackChunkName: "[homePage]" */ '@/pages/Home.vue'),
  },
  {
    path: '/tickets/',
    redirect: '/tickets/active',
  },
  {
    path: '/active',
    redirect: '/tickets/active',
  },
  {
    path: '/closed',
    redirect: '/tickets/closed',
  },
  {
    path: '/watched',
    redirect: '/tickets/watched',
  },
  {
    path: '/tickets/:source/:id',
    redirect: '/tickets/view-ticket/:source/:id',
  },
  {
    path: '/view-ticket/:source/:id',
    redirect: '/tickets/view-ticket/:source/:id',
  },
  {
    path: '/approval/:source/:id',
    redirect: '/myagree/approval/:source/:id',
  },
  {
    path: '/tickets/',
    name: 'CreateTicketWrapper',
    meta: {
      title: 'Мои заявки',
    },
    component: () => import(/* webpackChunkName: "[createTicketPage]" */ '@/pages/Ticket/CreateTicket.vue'),
    children: [
      {
        path: 'create/:source?/:serviceId?',
        name: 'CreateTicket',
        meta: {
          title: 'Создание заявки',
        },
      },
    ],
  },
  {
    path: '/tickets/',
    name: 'ViewTicketWrapper',
    meta: {
      title: 'Мои заявки',
    },
    component: () => import(/* webpackChunkName: "[viewTicketPage]" */ '@/pages/Ticket/ViewTicket.vue'),
    children: [
      {
        path: 'view-ticket/:source/:id',
        name: 'ViewTicket',
        meta: {
          title: 'Просмотр заявки',
        },
      },
    ],
  },
  {
    path: '/myagree',
    component: () => import(/* webpackChunkName: "[approvalsPage]" */ '@/pages/Ticket/Approvals.vue'),
    meta: {
      title: 'Мои согласования',
    },
    children: [
      {
        path: '/',
        name: 'Approvals',
        component: () => import(/* webpackChunkName: "[approvalsPage]" */ '@/pages/Ticket/Tickets.vue'),
      },
      {
        path: 'approval/:source/:id',
        name: 'Approval',
        component: () => import(/* webpackChunkName: "[viewTicketPage]" */ '@/pages/Ticket/ViewTicket.vue'),
        meta: {
          title: 'Просмотр согласования',
        },
      },
    ],
  },
  {
    path: '/tickets/:category',
    name: 'Tickets',
    meta: {
      title: 'Мои заявки',
    },
    component: () => import(/* webpackChunkName: "[ticketsPage]" */ '@/pages/Ticket/Tickets.vue'),
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: 'Новости',
    },
    component: () => import(/* webpackChunkName: "[newsPage]" */ '@/pages/News.vue'),
  },
  {
    path: '/news/',
    name: 'NewsItem',
    meta: {
      title: 'Новости',
    },
    component: () => import(/* webpackChunkName: "[newsItemPage]" */ '@/pages/NewsItem.vue'),
    children: [
      {
        path: ':id',
      },
    ],
  },

  {
    path: '/articles',
    name: 'Articles',
    meta: {
      title: 'Статьи',
    },
    component: () => import(/* webpackChunkName: "[articlesPage]" */ '@/pages/Articles.vue'),
  },
  {
    path: '/articles/',
    name: 'ArticlesItem',
    meta: {
      title: 'Статьи',
    },
    component: () => import(/* webpackChunkName: "[articlesItemPage]" */ '@/pages/ArticlesItem.vue'),
    children: [
      {
        path: ':id',
      },
    ],
  },

  {
    path: '/faq',
    name: 'FAQ',
    meta: {
      title: 'База знаний',
    },
    redirect: '/faq/category',
    component: () => import(/* webpackChunkName: "[faqPage]" */ '@/pages/Faq.vue'),
    children: [
      {
        path: 'category/:categoryId?',
        alias: '/faq',
        name: 'FAQCategory',
        meta: {
          title: 'Категория',
        },
        props: true,
        component: () => import(/* webpackChunkName: "[faqCategoryPage]" */ '@/pages/FaqCategory.vue'),
      },

      {
        path: 'article/:articleId',
        name: 'FAQArticle',
        meta: {
          title: 'Статья',
        },
        props: true,
        component: () => import(/* webpackChunkName: "[faqArticlePage]" */ '@/pages/FaqArticle.vue'),
      },
    ],
  },

  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'LayoutWithHeader',
      title: 'Авторизация',
    },
    component: () => import(/* webpackChunkName: "AuthPages" */ '@/pages/auth/Login.vue'),
  },

  {
    path: '/logout',
    name: 'Logout',
    meta: {
      layout: 'LayoutWithHeader',
      title: 'Выход из системы',
    },
    component: () => import(/* webpackChunkName: "AuthPages" */ '@/pages/auth/Logout.vue'),
  },

  {
    path: '/forget-password',
    name: 'ForgetPassword',
    meta: {
      layout: 'LayoutWithHeader',
      title: 'Восстановление пароля',
    },
    component: () => import(/* webpackChunkName: "ResetPasswordPage" */ '@/pages/auth/ForgetPassword.vue'),
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      layout: 'LayoutWithHeader',
      title: 'Создание нового пароля',
    },
    component: () => import(/* webpackChunkName: "ResetPasswordPage" */ '@/pages/auth/ResetPassword.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      title: 'Поиск',
    },
    component: () => import(/* webpackChunkName: "[searchPage]" */ '@/pages/Search.vue'),
  },
  {
    path: '/surveys',
    name: 'SurveysListWrapper',
    meta: {
      title: 'Опросы',
      layout: 'LayoutDefaultWithoutSearch',
    },
    component: () => import(/* webpackChunkName: "[surveysPage]" */ '@/pages/surveys/SurveysWrapper.vue'),
    children: [
      {
        path: '/',
        name: 'SurveysList',
        component: () => import(/* webpackChunkName: "[surveysPage]" */ '@/pages/surveys/Surveys.vue'),
      },
      {
        path: ':id',
        name: 'SurveyDetail',
        meta: {
          title: 'Опрос',
        },
        component: () => import(/* webpackChunkName: "[surveyPage]" */ '@/pages/surveys/SurveyDetail.vue'),
      },
    ],
  },
  {
    path: '/delegations',
    name: 'Delegations',
    meta: {
      title: 'Делегирование',
    },
    component: () => import(/* webpackChunkName: "[delegationsPage]" */ '@/pages/Delegations.vue'),
  },
  {
    path: '/delegations/',
    name: 'DelegationDetail',
    meta: {
      title: 'Делегирование',
    },
    component: () => import(/* webpackChunkName: "[delegationPage]" */ '@/pages/Delegation.vue'),
    children: [
      {
        path: ':id',
      },
    ],
  },
  {
    path: '/delegations/',
    name: 'Delegation',
    meta: {
      title: 'Делегирование',
    },
    component: () => import(/* webpackChunkName: "[createDelegationPage]" */ '@/pages/Delegation.vue'),
    children: [
      {
        path: 'create/delegation',
        meta: {
          title: 'Создание правила делегирования',
        },
      },
    ],
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      title: 'Личный кабинет',
    },
    redirect: '/account/my-info',
    component: () => import(/* webpackChunkName: "[accountPage]" */ '@/pages/Account.vue'),
    children: [
      {
        path: 'my-info',
        meta: {
          layout: 'LayoutDefaultWithoutSearch',
          title: 'Мои данные',
        },
        component: () => import(/* webpackChunkName: "[accountPage]" */ '@/components/account/AccountMyInfoTab'),
      },
      {
        path: 'my-templates',
        meta: {
          layout: 'LayoutDefaultWithoutSearch',
          title: 'Мои шаблоны',
        },
        component: () => import(/* webpackChunkName: "[accountPage]" */ '@/components/account/AccountMyTemplatesTab'),
      },
      {
        path: 'my-favorites',
        meta: {
          layout: 'LayoutDefaultWithoutSearch',
          title: 'Мои избранные услуги',
        },
        component: () => import(/* webpackChunkName: "[accountPage]" */ '@/components/account/AccountMyFavoritesTab'),
      },
    ],
  },
  // TODO: исключить роут в зависимости от настроек
  {
    path: '/business-trip',
    name: 'BusinessTripPage',
    meta: {
      title: 'Заказ командировки',
    },
    component: () => import(/* webpackChunkName: "[businessTripPage]" */ '@/pages/BusinessTrip.vue'),
  },
  {
    path: '/admin',
    meta: {
      title: 'Администрирование',
    },
    component: () => import(/* webpackChunkName: "[admin]" */ '@/pages/admin/Admin.vue'),
    children: [
      {
        path: '/',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "[admin-home]" */ '@/pages/admin/Home.vue'),
      },
      {
        path: 'search',
        name: 'AdminSearch',
        meta: {
          title: 'Поиск',
        },
        component: () => import(/* webpackChunkName: "[admin-search]" */ '@/pages/admin/Search.vue'),
      },
      {
        path: 'forms/:serviceId',
        meta: {
          title: 'Формы по услуге',
        },
        component: () => import(/* webpackChunkName: "[forms]" */ '@/pages/admin/forms/FormsWrapper.vue'),
        children: [
          {
            path: '/',
            name: 'Forms',
            component: () => import(/* webpackChunkName: "[forms]" */ '@/pages/admin/forms/Forms.vue'),
          },
          {
            path: 'edit/:id',
            name: 'EditForm',
            meta: {
              title: 'Редактирование форм',
            },
            component: () => import(/* webpackChunkName: "[forms]" */ '@/pages/admin/EditForm.vue'),
          },
          {
            path: 'create',
            name: 'CreateForm',
            meta: {
              title: 'Создание формы',
            },
            component: () => import(/* webpackChunkName: "[forms]" */ '@/pages/admin/EditForm.vue'),
          },
          {
            path: 'import',
            name: 'ImportForm',
            meta: {
              title: 'Импортирование формы',
            },
            component: () => import(/* webpackChunkName: "[forms]" */ '@/pages/admin/EditForm.vue'),
          },
        ],
      },
      {
        path: 'approvals',
        meta: {
          title: 'Согласования',
        },
        // eslint-disable-next-line max-len
        component: () => import(/* webpackChunkName: "[approvals-wrap]" */ '@/pages/admin/approvals/ApprovalsWrapper.vue'),
        children: [
          {
            path: '/',
            name: 'ApprovalsBuilder',
            component: () => import(/* webpackChunkName: "[approvals]" */ '@/pages/admin/approvals/Approvals.vue'),
          },
          {
            path: 'edit/:id',
            name: 'EditApproval',
            meta: {
              title: 'Редактирование формы согласования',
            },
            // eslint-disable-next-line max-len
            component: () => import(/* webpackChunkName: "[edit-approval]" */ '@/pages/admin/approvals/EditApproval.vue'),
          },
          {
            path: 'default',
            name: 'EditDefaultApproval',
            meta: {
              title: 'Редактирование формы согласования по умолчанию',
            },
            // eslint-disable-next-line max-len
            component: () => import(/* webpackChunkName: "[edit-default-approval]" */ '@/pages/admin/approvals/EditApproval.vue'),
          },
          {
            path: 'create',
            name: 'CreateApproval',
            meta: {
              title: 'Создание формы согласования',
            },
            // eslint-disable-next-line max-len
            component: () => import(/* webpackChunkName: "[create-approval]" */ '@/pages/admin/approvals/EditApproval.vue'),
          },
        ],
      },
      {
        path: 'default-form',
        name: 'DefaultForm',
        meta: {
          title: 'Форма по умолчанию',
        },
        component: () => import(/* webpackChunkName: "[default-form]" */ '@/pages/admin/EditForm.vue'),
      },
      {
        path: 'news',
        name: 'AdminNews',
        meta: {
          title: 'Новости',
        },
        component: () => import(/* webpackChunkName: "[admin-news]" */ '@/pages/admin/News.vue'),
      },
      {
        path: 'feedback',
        name: 'Feedback',
        meta: {
          title: 'Обратная связь',
        },
        component: () => import(/* webpackChunkName: "[feedback]" */ '@/pages/admin/feedback/Feedback.vue'),
      },
      {
        path: 'feedback/create',
        name: 'FeedbackCreate',
        meta: {
          title: 'Обратная связь',
        },
        component: () => import(/* webpackChunkName: "[feedback]" */ '@/pages/admin/feedback/FeedbackCreate.vue'),
      },
      {
        path: 'feedback/edit/:id',
        name: 'FeedbackEdit',
        meta: {
          title: 'Обратная связь',
        },
        component: () => import(/* webpackChunkName: "[feedback]" */ '@/pages/admin/feedback/FeedbackEdit.vue'),
      },
      {
        path: 'feasts',
        name: 'Feasts',
        meta: {
          title: 'Праздники',
        },
        component: () => import(/* webpackChunkName: "[feasts]" */ '@/pages/admin/feasts/Feasts.vue'),
      },

      {
        path: 'popular-services',
        name: 'PopularServices',
        meta: {
          title: 'Популярные услуги',
        },
        component: () => import(/* webpackChunkName: "[popular]" */ '@/pages/admin/popular/PopularServices.vue'),
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: {
          title: 'Настройки',
        },
        component: () => import(/* webpackChunkName: "[settings]" */ '@/pages/admin/settings/Settings.vue'),
      },
      {
        path: 'portal-style',
        name: 'PortalStyle',
        meta: {
          title: 'Конструктор портала',
        },
        component: () => import(/* webpackChunkName: "[portal-style]" */ '@/pages/admin/portal-style/PortalStyle.vue'),
      },
      {
        path: 'surveys',
        name: 'AdminSurveysWrapper',
        meta: {
          title: 'Опросы',
        },
        component: () => import(/* webpackChunkName: "[surveys]" */ '@/pages/surveys/SurveysWrapper.vue'),
        children: [
          {
            path: '/',
            name: 'Surveys',
            component: () => import(/* webpackChunkName: "[surveys]" */ '@/pages/admin/surveys/Surveys.vue'),
          },
          {
            path: 'create',
            name: 'CreateSurvey',
            meta: {
              title: 'Создание опроса',
            },
            component: () => import(/* webpackChunkName: "[surveys-edit]" */ '@/pages/admin/surveys/EditSurvey.vue'),
          },
          {
            path: ':id',
            name: 'EditSurvey',
            meta: {
              title: 'Редактирование опроса',
            },
            component: () => import(/* webpackChunkName: "[surveys-edit]" */ '@/pages/admin/surveys/EditSurvey.vue'),
          },
        ],
      },
      {
        path: 'documents',
        meta: {
          title: 'Шаблоны документов',
        },
        component: () => import(
          /* webpackChunkName: "[documents-wrapper]" */
          '@/pages/admin/documents/DocumentsWrapper.vue'
        ),
        children: [
          {
            path: '/',
            name: 'Documents',
            component: () => import(
              /* webpackChunkName: "[documents]" */ '@/pages/admin/documents/Documents.vue'
            ),
          },
          {
            path: 'edit/:id',
            name: 'EditDocument',
            meta: {
              title: 'Редактирование шаблона документа',
            },
            component: () => import(
              /* webpackChunkName: "[edit-document]" */
              '@/pages/admin/documents/EditDocument.vue'
            ),
          },
          {
            path: 'create',
            name: 'CreateDocument',
            meta: {
              title: 'Создание шаблона документа',
            },
            component: () => import(
              /* webpackChunkName: "[create-document]" */
              '@/pages/admin/documents/EditDocument.vue'
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      layout: 'LayoutDefaultWithoutSearch',
      title: 'О портале',
    },
    component: () => import(/* webpackChunkName: "about" */ '@/pages/About.vue'),
  },
  {
    path: '/statistics',
    name: 'Statistics',
    meta: {
      layout: 'LayoutDefaultWithoutSearch',
      title: 'Статистика',
    },
    component: () => import(/* webpackChunkName: "statistics" */ '@/pages/Statistics.vue'),
  },
  {
    path: '/error/:errorCode?',
    name: 'Error',
    props: true,
    meta: {
      layout: 'LayoutWithHeader',
      title: 'Ошибка...',
    },
    component: () => import(/* webpackChunkName: "[errorPage]" */ '@/pages/Error.vue'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    props: true,
    meta: {
      layout: 'LayoutClean',
      title: 'Технические работы',
    },
    component: () => import(/* webpackChunkName: "[errorPage]" */ '@/pages/Maintenance.vue'),
  },
  {
    path: '*',
    name: 'Error404',
    redirect: {
      name: 'Error',
      params: {
        errorCode: '404',
      },
    },
  },
];
