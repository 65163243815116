import system from './system';
import user from './user';
import sidebar from './sidebar';
import services from './services';
import news from './news';
import userTemplates from './userTemplates';
import search from './search';
import delegations from './delegations';
import ticket from './ticket';
import breadcrumbs from './breadcrumbs';
import businessCard from './businessCard';
import businessTrip from './businessTrip';
import businessTrip2 from './businessTrip2';
import feasts from './feasts';
import viewOptions from './viewOptions';
import articles from './articles';
import auth from './auth';
import employeeReplacement from './employeeReplacement';
import controlSearch from './controlSearch';
import subscriptions from './subscriptions';
import portalStyle from './portalStyle';
import dependencies from './dependencies';
import document from './document';

export default {
  system,
  user,
  sidebar,
  services,
  news,
  userTemplates,
  search,
  delegations,
  ticket,
  breadcrumbs,
  businessCard,
  businessTrip,
  businessTrip2,
  feasts,
  viewOptions,
  articles,
  auth,
  employeeReplacement,
  controlSearch,
  subscriptions,
  portalStyle,
  dependencies,
  document,
};
