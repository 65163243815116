const state = {
  documentAttachments: {},
};

const getters = {};

const mutations = {
  SET_DOCUMENT_ATTACHMENTS(state, val) {
    state.documentAttachments = val;
  },
};

const actions = {
  setDocumentAttachments({ commit }, attachments) {
    commit('SET_DOCUMENT_ATTACHMENTS', attachments);
  },
};

const document = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default document;
