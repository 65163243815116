// TODO: отрефакторить массив в объект. Пользоваться этим в виде SORT_TYPES[0] не удобно
export const SORT_TYPES = [
  {
    id: 'newup',
    name: 'по дате: новые сверху',
  },
  {
    id: 'newdown',
    name: 'по дате: новые снизу',
  },
  {
    id: 'tags',
    name: 'по услугам',
  },
  {
    id: 'status',
    name: 'по статусам',
  },
];

// TODO: isShowSolution отрефакторить (ESMPCP-1839)

export const STATUS_TYPES = {
  // otrs
  approval: {
    id: 'approval',
    name: 'На согласовании',
    color: 'purple',
    isShowSolution: false,
  },
  assigned: {
    id: 'assigned',
    name: 'Назначено',
    color: 'blue',
    isShowSolution: false,
  },
  closedSuccessful: {
    id: 'closed successful',
    name: 'Закрыто',
    color: 'gray',
    isShowSolution: true,
  },
  closedUnsuccessful: {
    id: 'closed unsuccessful',
    name: 'Закрыто',
    color: 'gray',
    isShowSolution: true,
  },
  executed: {
    id: 'executed',
    name: 'Выполнена',
    color: 'green',
    isShowSolution: true,
  },
  expired: {
    id: 'expired',
    name: 'Просрочено',
    color: 'red',
    isShowSolution: false,
  },
  merged: {
    id: 'merged',
    name: 'Объединены',
    color: 'yellow',
    isShowSolution: false,
  },
  new: {
    id: 'new',
    name: 'Зарегистрирована',
    color: 'blue',
    isShowSolution: false,
  },
  open: {
    id: 'open',
    name: 'В работе',
    color: 'blue',
    isShowSolution: false,
  },
  taskPendingDependency: {
    id: 'task pending dependency',
    name: 'В ожидании',
    color: 'purple',
    isShowSolution: false,
  },
  pendingClient: {
    id: 'pending client',
    name: 'В ожидании',
    color: 'purple', // 1
    isShowSolution: false,
  },
  pendingExecution: {
    id: 'pending execution',
    name: 'Отложенное исполнение',
    color: 'purple', // 1
    isShowSolution: false,
  },
  pendingReminder: {
    id: 'pending reminder',
    name: 'В ожидании',
    color: 'purple', // 1
    isShowSolution: false,
  },
  rejected: {
    id: 'rejected',
    name: 'Отклонено',
    color: 'red', // 1
    isShowSolution: true,
  },
  removed: {
    id: 'removed',
    name: 'Отменена пользователем',
    color: 'ruby', // 1
    isShowSolution: true,
  },
  revision: {
    id: 'revision',
    name: 'Открыто повторно',
    color: 'yellow', // 1
    isShowSolution: false,
  },
  initiatorReview: {
    id: 'initiator review',
    name: 'Отзыв клиентом',
    color: 'purple', // 1
    isShowSolution: false,
  },
  cancel: {
    id: 'cancel',
    name: 'Закрыта',
    color: 'gray',
    isShowSolution: true,
  },
  // sm
  pending: {
    id: 'pending',
    name: 'В ожидании согласования',
    color: 'purple',
    isShowSolution: false,
  },
  pendingApproval: {
    id: 'Pending Approval',
    name: 'На согласовании',
    color: 'purple', // 1
    isShowSolution: false,
  },
  closed: {
    id: 'Closed',
    name: 'Закрыто',
    color: 'gray', // 2
    isShowSolution: true,
  },
  closedLower: {
    id: 'closed',
    name: 'Закрыто',
    color: 'gray', // 2
    isShowSolution: true,
  },
  resolved: {
    id: 'Resolved',
    name: 'Работы выполнены',
    color: 'green', // 5
    isShowSolution: true,
  },
  openLinked: {
    id: 'Open - Linked',
    name: 'В работе',
    color: 'blue', // 6
    isShowSolution: false,
  },
  pendingRequestor: {
    id: 'Pending Requestor',
    name: 'Нуждается в уточнении',
    color: 'yellow', // 8
    isShowSolution: false,
  },
  reopen: {
    id: 'Reopen',
    name: 'Открыто повторно',
    color: 'yellow', // 10
    isShowSolution: false,
  },
  pendingVendor: {
    id: 'Pending Vendor',
    name: 'В ожидании подрядчика',
    color: 'blue', // 20
    isShowSolution: false,
  },
  workSuspended: {
    id: 'Work Suspended',
    name: 'Работы приостановлены',
    color: 'yellow', // 21
    isShowSolution: false,
  },
  openIdle: {
    id: 'Open - Idle',
    name: 'В ожидании обработки',
    color: 'blue', // 22
    isShowSolution: false,
  },
  pendingChange: {
    id: 'Pending Change',
    name: 'В ожидании изменения',
    color: 'blue', // 23
    isShowSolution: false,
  },
  pendingMethodologists: {
    id: 'Pending Methodologists',
    name: 'В ожидании методологов',
    color: 'blue', // 24
    isShowSolution: false,
  },
};

export const VIEW_TYPES = [
  'card',
  'line',
];

export const CATEGORY_TREE_VIEW_TYPES = {
  card: 'card',
  line: 'line',
};

export const CATEGORIES = {
  active: 'active',
  myagree: 'myagree',
  closed: 'closed',
  watched: 'watched',
  partner: 'partner',
  customer: 'customer',
};

export const CATEGORY_LABELS = {
  active: 'Активные заявки',
  myagree: 'Мои согласования',
  closed: 'Завершенные',
  watched: 'Отслеживаемые',
  partner: 'Заявки партнеров',
  customer: 'Мои заявки',
  inactive: 'Неактивные заявки',
};

export const TICKET_DECLINE_RESOLUTION_STATUSES = [
  /* 'Отсутствует техническая возможность',
  'Не соответствует поддерживаемым Услугам', */
  'В участии отказано',
  'Отклонено в ходе согласования',
  'Отменено по инициативе пользователя',
  'Отсутствует техническая возможность',
  /* 'Пользователь не предоставил информацию', */
];

export const REQUEST_AGREEMENT_MODES = {
  single: 'single',
  multi: 'multi',
};

export const TICKET_DESCRIPTION_MAX_LENGTH = 100;

export const CUSTOM_SERVICES = {
  // TODO: sometimes, we will can find service at otrs, but not today :(
  // businessTrip: {
  //   serviceId: '486',
  //   src: 'otrs1',
  // },

  // {
  //   serviceId: id услуги (env vars),
  //   serviceName: имя услуги (используется для индентификации в тикете, если есть необходимость - обычно нет),
  //   src: id мастер-системы,
  //   isFull: скрывать полностью отображение обычной подачи заявки (/tickets/create/),
  //   isEditable: показывать кнопку редактирования в тикете,
  //   isAvailableToCancelApproval: показывать кнопку отмены согласование в тикете,
  //   isAvailableToBook: показывать кнопку бронирования в тикете,
  //   componentName: имя компонента (должен быть подключен в /tickets/create/),
  //   hasCustomChecklist: есть ли кастомный компонента для чеклиста в тикете,
  //   customChecklistComponent: имя кастомного компонента для чеклиста в тикете,
  //   hidePatternBtn: скрывать кнопку шаблона,
  //   hideThemeInput: скрывать поле с темой,
  //   hideDescriptionInput: скрывать поле с дополнительной информацией,
  //   hidePhone: скрывать поле с телефоном,
  //   hideFileUploader: скрывать поле с загрузкой файла,
  //   config: объект с настройками для компонента,
  // },
  businessTrip: {
    serviceId: process.env.VUE_APP_BUSINESS_TRIP_ID,
    serviceName: 'Заказ командировок',
    src: process.env.VUE_APP_BUSINESS_TRIP_SRC,
    isFull: true,
    isEditable: true,
    isAvailableToCancelApproval: true,
    isAvailableToBook: true,
    componentName: 'businessTrip',
    hasCustomChecklist: true,
    customChecklistComponent: 'businessTripChecklist', // ticket
  },
  businessCard: {
    serviceId: process.env.VUE_APP_BUSINESS_CARD_ID,
    serviceName: 'Заказ визитных карт',
    src: 'otrs1',
    isFull: false,
    isEditable: false,
    isAvailableToCancelApproval: false,
    isAvailableToBook: false,
    componentName: 'businessCard',
    hasCustomChecklist: false,
    hidePatternBtn: false,
    hideThemeInput: true,
    hideDescriptionInput: true,
    hidePhone: false,
    hideFileUploader: true,
    config: {
      fieldSet: process.env.VUE_APP_BUSINESS_CARD_ID,
    },
  },
  /* giftCard: {
    serviceId: process.env.VUE_APP_GIFT_CARD_ID,
    serviceName: 'Поздравительные открытки',
    src: 'sm1',
    isFull: false,
    isEditable: false,
    isAvailableToCancelApproval: false,
    isAvailableToBook: false,
    componentName: 'giftCard',
    hideChecklist: true,
    hasCustomChecklist: false,
  },
  userLocation: {
    serviceId: process.env.VUE_APP_USER_LOCATION_ID,
    serviceName: 'Актуализация размещения',
    src: 'sm1',
    isFull: false,
    isEditable: false,
    isAvailableToCancelApproval: false,
    isAvailableToBook: false,
    componentName: 'userLocation',
    hideChecklist: false,
    hasCustomChecklist: false,
  }, */
  externalTraining: {
    serviceId: process.env.VUE_APP_EXTERNAL_TRAINING_ID,
    serviceName: 'Заявка на внешнее обучение',
    src: 'otrs1',
    isFull: false,
    isEditable: false,
    isAvailableToCancelApproval: false,
    isAvailableToBook: false,
    componentName: 'ExternalTraining',
    hasCustomChecklist: false,
    hidePatternBtn: false,
    hideThemeInput: true,
    hideDescriptionInput: true,
    hidePhone: false,
    hideFileUploader: true,
    config: {
      typeId: process.env.VUE_APP_EXTERNAL_TRAINING_TYPE_ID,
      individualId: process.env.VUE_APP_EXTERNAL_TRAINING_INDIVIDUAL_ID,
      groupId: process.env.VUE_APP_EXTERNAL_TRAINING_GROUP_ID,
    },
  },
  dismissalTemplate: {
    serviceId: process.env.VUE_APP_DISMISSAL_ID,
    serviceName: 'Увольнение',
    src: 'otrs1',
    isFull: false,
    isEditable: false,
    isAvailableToCancelApproval: false,
    isAvailableToBook: false,
    isAction: true,
    componentName: 'DismissalTemplate',
    hasCustomChecklist: false,
  },
};

export const TREE_LEVELS = [
  'firstLevel',
  'secondLevel',
  'thirdLevel',
  'fourthLevel',
];

// TODO со статусами жесть полная, почему такие костыли на фронте, это все должно на беке делаться!!!
// статусы для согласований
export const AGREEMENT_TYPES_MAP = {
  any: 'Любой',
  most: 'Большинство',
  all: 'Все',
  unknown: 'Неизвестно',
};
export const APPROVE_STATUSES_MAP = {
  Denied: {
    weight: 7,
    code: 'Denied',
    color: 'red',
    translate: 'Отклонено',
  },
  CancelledByCustomer: {
    weight: 6,
    code: 'CancelledByCustomer',
    color: 'gray',
    translate: 'Отозвано клиентом',
  },
  CancelledByExecutor: {
    weight: 5,
    code: 'CancelledByExecutor',
    color: 'gray',
    translate: 'Отменено исполнителем',
  },
  Approved: {
    weight: 4,
    code: 'Approved',
    color: 'green',
    translate: 'Согласовано',
  },
  Future: {
    weight: 3,
    code: 'Future',
    color: 'gray',
    translate: 'В очереди',
  },
  Pending: {
    weight: 2,
    code: 'Pending',
    color: 'blue',
    translate: 'В процессе согласования',
  },
  Closed: {
    weight: 1,
    code: 'Closed',
    color: 'gray',
    translate: 'Закрыто',
  },
  getArray() {
    return [
      this.Denied,
      this.CancelledByCustomer,
      this.CancelledByExecutor,
      this.Approved,
      this.Future,
      this.Pending,
      this.Closed,
    ];
  },
  sortByWeight(sortType = 'asc') {
    return this.getArray().sort((a, b) => (sortType === 'asc' ? a.weight - b.weight : b.weight - a.weight));
  },
};

export const TICKETS_PER_PAGE_LIST = [20, 50, 100];

export default {
  SORT_TYPES,
  CATEGORIES,
};
