export default ($axios) => ({
// Получение списка праздников
  getFeasts(page = 0, size = 30, state = 'active') {
    const params = { page, size, state };
    return $axios
      .get('/feasts', params)
      .then((res) => res);
  },

  // Получение выбранного праздника
  getFeast(id) {
    return $axios
      .get(`/feasts/${id}`)
      .then((res) => res);
  },

  // Отправить открытку
  sendPostcard(data) {
    return $axios
      .post('/feasts/send-postcard', data)
      .then((res) => res);
  },

  createFeast(data) {
    return $axios
      .post('/admin/feasts', data)
      .then((res) => res);
  },

  updateFeast(id, data) {
    return $axios
      .put(`/admin/feasts/${id}/`, data)
      .then((res) => res);
  },

  updateFeastState(id, data) {
    return $axios
      .patch(`/admin/feasts/${id}/update-state`, data)
      .then((res) => res);
  },

  getPostcardsServices() {
    return $axios
      .get('/admin/postcards-services')
      .then((res) => res);
  },

  addPostcardsService(data) {
    return $axios
      .post('/admin/postcards-services', data)
      .then((res) => res);
  },

  deletePostcardsService(params = {
    serviceId: undefined,
    sourceSystem: undefined,
  }) {
    return $axios
      .delete('/admin/postcards-services', { params })
      .then((res) => res);
  },
});
