export default ($axios) => ({
  getAll() {
    return $axios
      .get('/employeeInfoItems')
      .then((res) => res);
  },
  updateAll(data = null) {
    return $axios
      .put('/employeeInfoItems', data)
      .then((res) => res);
  },
  getLoginByPhone(params = { phone: undefined }) {
    return $axios
      .get('/employeeInfoItems/login-by-phone', { params })
      .then((res) => res);
  },
});
