import Vue from 'vue';
import dayjs from 'dayjs';
import axios from 'axios';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isEmpty from 'lodash/isEmpty';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/ru.json';
import { businessTrip } from '@/constants/businessTrip';
import api from '@/services/api';

dayjs.locale('ru');
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

extend('database', {
  validate(value) {
    return !value.some((el) => el.required && !el.value);
  },
  message: 'Необходимо заполнить поле',
});

extend('dateBefore', {
  validate(value) {
    return dayjs().startOf('day').valueOf() > dayjs(value, 'DD.MM.YYYY')
      .startOf('day')
      .valueOf();
  },
  message: 'Дата должна быть прошедшей',
});

extend('safeString', {
  validate(value) {
    return !/<\/?[^>]+>/m.test(value);
  },
  message: 'В тексте не должно быть тегов (<tag>)',
});

extend('dateAfter', {
  validate(value) {
    return dayjs().startOf('day').valueOf() < dayjs(value, 'DD.MM.YYYY')
      .startOf('day')
      .valueOf();
  },
  message: 'Дата должна быть будущей',
});

const getDateFormat = (date, showTime) => {
  if (typeof date === 'object') return undefined;
  return showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
};

extend('dateBeforeAndToday', {
  params: ['showTime', 'viewMode'],
  validate(value, { showTime, viewMode }) {
    let dateToCheck = value;
    // eslint-disable-next-line prefer-destructuring
    if (viewMode === 'range') dateToCheck = value[1];

    return dayjs().startOf('day').valueOf() >= dayjs(dateToCheck, getDateFormat(dateToCheck, showTime))
      .startOf('day')
      .valueOf();
  },
  message: 'Дата должна быть прошедшей или текущей',
});

extend('dateAfterAndToday', {
  params: ['showTime', 'viewMode'],
  validate(value, { showTime, viewMode }) {
    let dateToCheck = value;
    // eslint-disable-next-line prefer-destructuring
    if (viewMode === 'range') dateToCheck = value[0];

    return dayjs().startOf('day').valueOf() <= dayjs(dateToCheck, getDateFormat(dateToCheck, showTime))
      .startOf('day')
      .valueOf();
  },
  message: 'Дата должна быть будущей или текущей',
});

// eslint-disable-next-line max-len
const chekIp = (v) => /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v);

extend('ip', {
  validate(value) {
    return chekIp(value);
  },

  message: 'Некорректный ip адрес',
});

extend('network', {
  async validate(value) {
    let valid = false;
    if (value.type === 'internet') return true;

    const localValidation = (obj) => {
      if (obj.type === 'host') return chekIp(obj.ipAddress);
      if (obj.type === 'network') {
        return chekIp(obj.ipAddress) && obj.netMask.length;
      }
      if (obj.type === 'range') {
        return chekIp(obj.startIpAddress) && chekIp(obj.endIpAddress);
      }
      return false;
    };

    if (localValidation(value)) {
      await axios
        .post('/api/checklists/validate-ip-address-info', value)
        .then((res) => { valid = Boolean(res.data); })
        .catch((e) => {
          console.error(e.response.data);
        });
    }
    return valid;
  },

  message: 'Некорректный ip адрес',
});

extend('resource', {
  params: ['isShowAccessType'],
  validate(resources, { isShowAccessType }) {
    let valid = false;
    if (resources && resources.length) {
      const condition = (el) => (isShowAccessType
        ? el.resource && el.resource.id && el.roles && el.roles[0].id
        : el.resource && el.resource.id);
      valid = resources.every((el) => condition(el));
    }
    return valid;
  },
  message: 'Необходимо добавить ресурс/роль',
});

extend('dontGPH', {
  validate(user) {
    let valid = false;
    if (user) {
      valid = !user.isGPH;
    }
    return valid;
  },
  message: 'Ошибка доступа',
});

extend('city', {
  validate(city) {
    let valid = false;
    if (city) {
      if (city.region) {
        if ((city.region.abbrev === businessTrip.DESTINATION.cityAbbrev)
          || (city.area?.abbrev === businessTrip.DESTINATION.cityAbbrev)) {
          valid = true;
        } else {
          valid = !!city.city || !!city.settlement;
        }
      }
    }
    return valid;
  },
  message: 'Некорректный населенный пункт',
});

extend('datePeriod', {
  params: ['startDay', 'endDay'],
  validate: (value, { startDay, endDay }) => {
    if (startDay || endDay) {
      const startDayValue = dayjs(startDay || value);
      const endDayValue = dayjs(endDay || value);

      return startDayValue.isSameOrBefore(endDayValue);
    }

    return true;
  },
  message: 'Некорректная дата',
});

extend('truely', {
  validate(value) {
    return value === true;
  },
  message: 'Обязательное поле',
});

extend('replacement', {
  params: ['showEmployee'],
  validate(value, { showEmployee }) {
    return !isEmpty(value)
      && (!showEmployee || !!value.employeeLogin)
      && !!value.region
      && !!value.address
      && !!value.floor
      && !!value.room;
  },
  message: 'Поля не заполнены и/или заполнены некорректно',
});

extend('replacementWorkPlace', {
  validate(value) {
    return value?.workPlace?.length;
  },
  message: 'Необходимо выбрать рабочее место',
});

extend('passwordComplexity', {
  validate(value) {
    return value.length >= 8
      && /[A-Z]/.test(value)
      && /[a-z]/.test(value)
      && /\d/.test(value)
      && (/\W/.test(value) || /_/.test(value));
  },
  message: 'Пароль должен содержать не менее 8 символов, не менее 1 заглавной, '
    + "1 строчной, 1 символа ( !@#$%^&*()_+=[\\{]}'+;:<>|./?,- ) и 1 цифры.",
});

extend('delegation', {
  params: ['selectedUserLogin', 'fromLogin'],
  validate(value, { selectedUserLogin, fromLogin }) {
    return selectedUserLogin !== fromLogin;
  },
  message: 'Нельзя делегировать полномочия на себя',
});

extend('inn', {
  validate(value) {
    if (
      typeof value !== 'string'
      || (value.length !== 10 && value.length !== 12)
      || value.split('').some((symbol) => Number.isNaN(Number(symbol)))
    ) return false;

    if (value.length === 10) {
      return Number(value[9]) === (value.split('').slice(0, -1)
        .reduce(
          (summ, symbol, index) => [2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
          0,
        ) % 11) % 10;
    }

    if (value.length === 12) {
      const checkSumOne = (value.split('').slice(0, -2)
        .reduce(
          (summ, symbol, index) => [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
          0,
        ) % 11) % 10;

      const checkSumTwo = (value.split('').slice(0, -1)
        .reduce(
          (summ, symbol, index) => [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
          0,
        ) % 11) % 10;

      return (checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11]));
    }

    return false;
  },
  message: 'Неправильный ИНН',
});

extend('calculatedField', {
  validate: async (value) => {
    let isValid;
    await api.controls.validateCalculationFormula(value).then(({ data }) => {
      isValid = data;
    });
    return isValid;
  },
  message: 'Некорректно заполнена формула',
});

extend('document', {
  params: ['isRequired'],
  validate(value, { isRequired }) {
    if (isRequired === 'true') {
      const hasEmptyModel = value.find((model) => Object.keys(model).length === 0) || false;
      if (!hasEmptyModel) {
        return true;
      }
      return false;
    }
    return true;
  },
  message: 'Обязательный документ должен быть заполнен',
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
