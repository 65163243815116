export default ($axios) => ({
  // Получение списка новостей
  getNews(params = {
    ignoreSubscriptions: undefined,
    state: undefined,
    isMain: undefined,
    isPublic: undefined,
    page: undefined,
    size: undefined,
    sortBy: undefined,
  }) {
    return $axios
      .get('/news', { params })
      .then((res) => res);
  },
  // Создание или редактирование новости
  editOrCreateNewsItem(id = null, data = null) {
    const endpoint = id ? `/news/${id}` : '/news';
    return $axios
      .post(endpoint, data)
      .then((res) => res);
  },
  // Получение новости
  getNewsItem(id) {
    return $axios
      .get(`/news/${id}`)
      .then((res) => res);
  },
  // Удаление новости
  deleteNewsItem(id) {
    return $axios
      .delete(`/news/${id}`)
      .then((res) => res);
  },
});
