export default ($axios) => ({
  getForms(serviceId, source = 'otrs1') {
    return $axios
      .get(`/admin/forms/service/${serviceId}?source=${source}`)
      .then((res) => res);
  },
  copyForm(formId, serviceId, sourceSystem = 'otrs1') {
    return $axios.post('/admin/forms/copy', {
      formId,
      serviceId,
      sourceSystem,
    }).then((res) => res);
  },
  importForm(sourceSystem, smChecklistId, serviceId, formSubscriptions) {
    return $axios
      .post('/admin/forms/import-from-sm', {
        sourceSystem,
        smChecklistId,
        serviceId,
        formSubscriptions,
      })
      .then((res) => res);
  },
  deleteForm(formId, source = 'otrs1') {
    return $axios
      .delete(`/admin/forms/${formId}?source=${source}`)
      .then((res) => res);
  },
  getForm(serviceId, id, source = 'otrs1') {
    return $axios
      .get(`/admin/form/${id}?source=${source}`)
      .then((res) => res);
  },
  getDefaultForm(source = 'otrs1') {
    return $axios
      .get(`/admin/forms/default?source=${source}`)
      .then((res) => res);
  },
  updateFormInfo(dto, sourceSystem = 'otrs1') {
    return $axios
      .patch('/admin/forms', { ...dto, sourceSystem }).then((res) => res);
  },
  createForm(dto) {
    return $axios
      .post('/admin/forms', dto)
      .then((res) => res);
  },
  updateForm(dto) {
    return $axios
      .put('/admin/forms', dto)
      .then((res) => res);
  },
  updateDefaultForm(dto) {
    return $axios
      .put('/admin/forms/default', dto)
      .then((res) => res);
  },
  checkSubscriptions(serviceId, formSubscriptions, sourceSystem = 'otrs1') {
    return $axios.post('/admin/form-subscription-check', {
      serviceId,
      formSubscriptions,
      sourceSystem,
    }).then((res) => res);
  },
  getFormVersions(formId, page, size) {
    return $axios
      .get(`/admin/form/${formId}/versions`, { params: { page, size } })
      .then((res) => res);
  },
  deleteFormVersion(formId, formVersionId) {
    return $axios
      .delete(`/admin/form/${formId}`, { params: { formVersionId } })
      .then((res) => res);
  },
  updateFormActiveVersion(formId, formVersionId) {
    return $axios
      .patch(`/admin/form/${formId}?formVersionId=${formVersionId}`)
      .then((res) => res);
  },
  getDiffFormVersions(formId, originalFormVersionId, changedFormVersionId) {
    return $axios
      .get(`/admin/form/${formId}/getDiff`, { params: { originalFormVersionId, changedFormVersionId } })
      .then((res) => res);
  },
});
