class Queuer {
  constructor() {
    this.queue = {};
  }

  set(key, callback) {
    if (this.queue[key]) return false;

    this.queue[key] = callback;
    return true;
  }

  remove(key) {
    delete this.queue[key];
  }

  isIn(key) {
    return !!this.queue[key];
  }

  /**
   * Выполнить функцию, записав ее в очередь исполнения.
   * @param key {string} - уникальный ключ
   * @param callback {Function} - колбек, желательно возвращающий Promise
   * @return {Promise<*|void>} - промис с результатом выполнения колбек функции, если он есть
   */
  async call(key, callback) {
    if (!callback && typeof callback !== 'function') { throw new Error(`No callback function for key ${key}`); }
    if (!key && typeof key !== 'string') { throw new Error('Key is required!'); }
    if (this.isIn(key)) return;
    try {
      this.set(key, callback);

      const result = await callback();

      await Promise.resolve(result || null);
    } catch {
      await Promise.reject(new Error(`Something wrong at callback with key ${key}`));
    } finally {
      this.remove(key);
    }
  }
}

export default Queuer;
