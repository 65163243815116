const state = {
  regions: [],
};

const getters = {};

const mutations = {
  SET_REGIONS(state, regions) {
    state.regions = regions;
  },
};

const actions = {
  getRegions({ commit, state }, { isEnabledWorkPlace, noCache }) {
    if (state.regions?.length && !noCache) {
      return state.regions;
    }
    return this.$API.employeeReplace.searchWorkPlacesInfo({
      isEnabledWorkPlace,
    }).then(({ data }) => {
      const regions = data.searchResults.map((r) => ({
        name: r,
      }));
      commit('SET_REGIONS', regions);
      return regions;
    });
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
