export default ($axios) => ({
  getTicketList(params = {
    category: undefined,
    createTimeBegin: undefined,
    createTimeEnd: undefined,
    solutionTimeViolation: undefined,
    sortBy: undefined,
    allRequestParams: undefined,
    limit: undefined,
    offset: undefined,
  }) {
    return $axios
      .get('/tickets', { params })
      .then((res) => res);
  },
  createTicket(data = null) {
    return $axios
      .post('/tickets', data)
      .then((res) => res);
  },
  getTicket(id, params = {
    source: undefined,
  }) {
    return $axios
      .get(`/tickets/${id}`, { params })
      .then((res) => res);
  },
  cancelTicket(id, data = null, params = { source: undefined }) {
    return $axios
      .post(`/tickets/${id}/action/cancel`, data, { params })
      .then((res) => res);
  },
  addCommentToTicket(id, data = null) {
    return $axios
      .post(`/tickets/${id}/action/comment`, data)
      .then((res) => res);
  },
  rateTicket(id, data = null, params = { source: undefined }) {
    return $axios
      .post(`/tickets/${id}/action/rate`, data, { params })
      .then((res) => res);
  },
  reopenTicket(id, data = null) {
    return $axios
      .post(`/tickets/${id}/action/reopen`, data)
      .then((res) => res);
  },
  addAttachmentToTicket(id, data = null) {
    return $axios
      .post(`/tickets/${id}/attachments`, data)
      .then((res) => res);
  },
  setWatchers(id, data = null, params = {
    source: undefined,
    serviceName: undefined,
  }) {
    return $axios
      .post(`/tickets/${id}/watchers`, data, { params })
      .then((res) => res);
  },
  deleteWatcherFromTicket(id, params = {
    source: undefined,
  }) {
    return $axios
      .delete(`/tickets/${id}/watchers`, { params })
      .then((res) => res);
  },
  getTicketAttachment(type, id, commentId, attachmentId, params = {
    source: undefined,
    attachmentName: undefined,
  }) {
    return $axios
      .get(`/tickets/${type}/${id}/attachments/${commentId}/${attachmentId}`, { params })
      .then((res) => res);
  },
  createTicketLatest(data = null) {
    return $axios
      .post('/tickets/actual', data)
      .then((res) => res);
  },
  getAttachmentExtensionsWhiteList(params = {
    source: undefined,
  }) {
    return $axios
      .get('/tickets/attachments/whiteList', { params })
      .then((res) => res);
  },
  getTicketCounters(params = {
    createTimeBegin: undefined,
    createTimeEnd: undefined,
    solutionTimeViolation: undefined,
  }) {
    return $axios
      .get('/tickets/counters', { params })
      .then((res) => res);
  },
  getTicketFilters() {
    return $axios
      .get('/tickets/filters')
      .then((res) => res);
  },

  /**
   * Поиск заявок
   * @link http://dev-tbkrv.ks.rt.ru:8080/swagger-ui.html#/Ticket%20operations/searchTicketsUsingGET
   *
   * @param query {string} - поисковый запрос
   * @returns {promise}
   */
  searchTickets(query) {
    const params = { query };

    return $axios
      .get('/tickets/search', { params })
      .then((res) => res.data);
  },
});
