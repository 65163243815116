import Vue from 'vue';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import {
  REFERENCE,
  DEFAULT_ARTICLE,
  BUSINESS_TRIP_WORD_CASES,
  DAY_PRICE,
  TICKET_PRICE,
  SERVICE_CHARGE,
} from '@/constants/business-trip2';

export const state = {
  isEdit: false,

  activeUser: null,
  initiatorUser: null,
  userCity: null,

  targets: null,
  cfd: null,
  businessProcesses: null,
  codePrograms: null,
  limits: null,
  constants: null,
  freeFund: null,

  commonData: {
    phone: '',
    target: null,
    details: '',

    cfd: null,
    articles: DEFAULT_ARTICLE,
    project: null,

    head: null,
    businessProcess: null,

    hasCodeProgram: false,
    codeProgram: null,
  },

  expenses: {
    cities: [],
    organization: {
      id: 'ПАО «Ростелеком»',
      name: 'ПАО «Ростелеком»',
    },
    otherOrganization: '',
    commonDayStart: null,
    commonDayEnd: null,
    isSelfBooking: false,
    isNeedHelp: false,
    groupBooking: false,
    avansAmount: '',
    avansAmountComment: null,
  },

  transit: [],
  accommodation: [],
};

export const getters = {
  targets: () => {
    if (state.targets && state.targets.length) {
      return state.targets.map((target, index) => ({
        id: `target_${index}`,
        name: target.name ? `${target.name}` : null,
        description: target.description ? `${target.description}` : null,
      }));
    }
    return null;
  },
  cfd: () => {
    if (state.cfd && state.cfd.length) {
      return state.cfd.map((cfd, index) => ({
        id: `cfd_${index}`,
        name: cfd.name ? `${cfd.name}` : null,
        code: cfd.code ? `${cfd.code}` : null,
        shortName: cfd.shortName ? `${cfd.shortName}` : null,
      }));
    }
    return null;
  },
  businessProcesses: () => {
    if (state.businessProcesses && state.businessProcesses.length) {
      return state.businessProcesses.map((process) => ({
        id: process.businessProcessId ? `${process.businessProcessId}` : null,
        name: process.businessProcessName
          ? `${process.businessProcessName}`
          : null,
      }));
    }
    return null;
  },
  territorialZones: () => {
    if (state.limits && state.limits.length) {
      return state.limits.map((limit) => ({
        id: limit.code ? `${limit.code}` : null,
        name: limit.regionName ? `${limit.regionName}` : null,
      }));
    }
    return null;
  },
  avansComments: () => (state.constants ? state.constants.prepaymentComment : []),
  organizations: () => (state.constants ? state.constants.organizations : []),
  articles: () => (state.constants ? state.constants.articles : []),
  timeOfDay: () => (state.constants ? state.constants.timeOfDay : []),
  freeFund: () => (state.freeFund && state.freeFund.funds
    ? Number(state.freeFund.funds).toLocaleString('RU-ru')
    : null),

  commonTripDays: () => {
    if (state.expenses.commonDayStart && state.expenses.commonDayEnd) {
      const commonDayStart = dayjs(state.expenses.commonDayStart);
      const commonDayEnd = dayjs(state.expenses.commonDayEnd);

      return commonDayEnd.diff(commonDayStart, 'd') + 1;
    }
    return BUSINESS_TRIP_WORD_CASES.dayDuration.DEFAULT;
  },
  dailyAllowances: () => {
    const commonTripDays = getters.commonTripDays();
    if (commonTripDays && commonTripDays > 1) {
      return parseInt(DAY_PRICE * commonTripDays, 10);
    }
    return 0;
  },
  transitAllowances: () => {
    let value = 0;
    state.transit.forEach((transit) => {
      if (!transit.zone) return;

      const way = transit.transitWay;
      const limit = state.limits.find(
        (limitItem) => limitItem.code === transit.zone.id,
      );
      if (limit) {
        const price = limit[`${TICKET_PRICE[way]}TicketsLimit`];

        const serviceCharge = SERVICE_CHARGE[way];

        value += price + serviceCharge;
        value = price + serviceCharge;
      }
    });

    return value;
  },
  accommodationAllowances: () => {
    let value = 0;
    state.accommodation.forEach((item) => {
      if (!(item.zone && item.dayStart && item.dayEnd)) return;

      const limit = state.limits.find(
        (limitItem) => limitItem.code === item.zone.id,
      );
      if (limit) {
        const price = limit.hotelLimit;
        const dayStart = dayjs(item.dayStart);
        const dayEnd = dayjs(item.dayEnd);
        const daysCount = dayEnd.diff(dayStart, 'd');
        const cost = price * daysCount;

        value += cost + SERVICE_CHARGE.hotel;
      }
    });

    return value;
  },
};

export const mutations = {
  SET_IS_EDIT(state, isEdit) {
    state.isEdit = isEdit;
  },
  // user
  SET_ACTIVE_USER(state, user) {
    state.activeUser = user;
  },
  SET_INITIATOR_USER(state, user) {
    state.initiatorUser = user;
  },
  SET_USER_CITY(state, city) {
    state.userCity = city;
  },

  SET_TARGETS(state, targets) {
    state.targets = targets;
  },
  SET_CFD(state, cfd) {
    state.cfd = cfd;
  },
  SET_LIMITS(state, limits) {
    state.limits = limits;
  },
  SET_CONSTANTS(state, comments) {
    state.constants = comments;
  },
  SET_FREE_FUND(state, freeFund) {
    state.freeFund = freeFund;
  },
  SET_BUSINESS_PROCESSES(state, businessProcesses) {
    state.businessProcesses = businessProcesses;
  },
  SET_CODE_PROGRAMS(state, programs) {
    state.codePrograms = programs;
  },

  SET_COMMON_DATA_FIELD(state, { field, value }) {
    Vue.set(state.commonData, field, value);
  },

  // expenses
  UPDATE_EXPENSES_CITY(state, { index, city }) {
    const numberIndex = Number(index);
    const cities = [...state.expenses.cities];
    cities.splice(numberIndex, 1, city);

    Vue.set(state.expenses, 'cities', cities);
  },
  UPDATE_EXPENSES(state, { field, value }) {
    Vue.set(state.expenses, field, value);
  },
  ADD_EXPENSES_CITY(state) {
    const expensesCity = cloneDeep(REFERENCE.expensesCity);
    const now = new Date();
    expensesCity.id = `expensesCity_${now.getTime()}`;
    const cities = [...state.expenses.cities];
    cities.push(expensesCity);
    Vue.set(state.expenses, 'cities', cities);
  },
  REMOVE_EXPENSES_CITY(state, index) {
    const numberIndex = Number(index);
    const cities = [...state.expenses.cities];
    cities.splice(numberIndex, 1);

    Vue.set(state.expenses, 'cities', cities);
  },
  CLEAR_EXPENSES(state) {
    const clearExpenses = cloneDeep(REFERENCE.expenses);
    Vue.set(state, 'expenses', clearExpenses);
  },

  // transit
  ADD_TRANSIT(state, addingPoint) {
    const copyTransitReference = cloneDeep(REFERENCE.transit);

    const now = new Date();
    copyTransitReference.id = `TransitReference_${now.getTime()}`;

    const transit = [...state.transit];
    transit.push(copyTransitReference);
    Vue.set(state, 'transit', transit);

    if (addingPoint) {
      const index = state.transit.length - 1;
      const currentStartPoint = state.transit[index].startPoint;
      const prevEndPoint = index ? state.transit[index - 1].endPoint : null;
      if (index && isEmpty(currentStartPoint) && !isEmpty(prevEndPoint)) {
        Vue.set(
          state.transit[index],
          'startPoint',
          JSON.parse(JSON.stringify(prevEndPoint)),
        );
      }
    }
  },
  REMOVE_TRANSIT_BLOCK(state, index) {
    const numberIndex = Number(index);
    const transit = [...state.transit];
    transit.splice(numberIndex, 1);

    Vue.set(state, 'transit', transit);
  },
  UPDATE_TRANSIT(state, { index, field, value }) {
    Vue.set(state.transit[index], field, value);
  },
  CLEAR_TRANSIT(state) {
    Vue.set(state, 'transit', []);
  },

  // accommodation
  CLEAR_ACCOMMODATION(state) {
    Vue.set(state, 'accommodation', []);
  },
  UPDATE_ACCOMMODATION(state, { index, field, value }) {
    Vue.set(state.accommodation[index], field, value);
  },
  ADD_ACCOMMODATION(state) {
    const accommodationReference = cloneDeep(REFERENCE.accommodation);
    const now = new Date();
    accommodationReference.id = `accommodation_${now.getTime()}`;

    const accommodation = [...state.accommodation];
    accommodation.push(accommodationReference);
    Vue.set(state, 'accommodation', accommodation);
  },
  REMOVE_ACCOMMODATION(state, index) {
    console.log('index', index);
    const numberIndex = Number(index);
    const accommodation = [...state.accommodation];
    accommodation.splice(numberIndex, 1);

    Vue.set(state, 'accommodation', accommodation);
  },

  CLEAR_STATE(state) {
    state.isEdit = false;

    state.activeUser = null;
    state.initiatorUser = null;
    state.userCity = null;

    state.targets = null;
    state.cfd = null;
    state.businessProcesses = null;
    state.codePrograms = null;
    state.limits = null;
    state.constants = null;
    state.freeFund = null;

    const clearCommonData = cloneDeep(REFERENCE.commonData);
    Vue.set(state, 'commonData', clearCommonData);
  },
};

export const actions = {
  getTargets({ commit }) {
    return axios.get('/api/business-trip/goals').then((response) => {
      commit('SET_TARGETS', response.data);
    });
  },
  getCfd({ commit }) {
    return axios.get('/api/business-trip/cfos').then((response) => {
      commit('SET_CFD', response.data);
    });
  },
  getLimits({ commit }, params) {
    return axios
      .get('/api/business-trip/limits', {
        params,
      })
      .then((response) => {
        commit('SET_LIMITS', response.data);

        return response;
      });
  },
  getConstants({ commit }) {
    return axios.get('/api/business-trip/constants').then((response) => {
      commit('SET_CONSTANTS', response.data);

      return response;
    });
  },
  setIsEdit({ commit }, isEdit) {
    commit('SET_IS_EDIT', isEdit);
  },
  getRegion(context, regionCode) {
    return axios
      .get('/api/business-trip/addresses', {
        params: {
          searchType: 0, // Region
          regionCode,
        },
      })
      .then((response) => response.data[0])
      .catch((e) => e);
  },
  getArea(context, { regionCode, areaCode }) {
    return axios
      .get('/api/business-trip/addresses', {
        params: {
          searchType: 1, // Area
          regionCode,
          districtCode: areaCode,
        },
      })
      .then((response) => response.data[0])
      .catch((e) => e);
  },
  getBookingRef({ commit }, { ticketId, email }) {
    axios
      .get(`/api/getBookingRef/${ticketId}`, {
        params: { email },
      })
      .then((response) => {
        commit('setFreeFund', response.data);
      });
  },
  addExpensesCity({ commit }) {
    commit('ADD_EXPENSES_CITY');
  },
  removeExpensesCity({ commit }, index) {
    commit('REMOVE_EXPENSES_CITY', index);
  },
  updateExpenses({ commit }, { field, value, list }) {
    let val = value;
    if (list && list.length) {
      val = list.find((i) => i.id === value);
    }
    commit('UPDATE_EXPENSES', { field, value: val });
  },
  clearExpenses({ commit }) {
    commit('CLEAR_EXPENSES');
  },
  addTransit({ commit }, addingPoint) {
    commit('ADD_TRANSIT', addingPoint);
  },
  removeTransitBlock({ commit }, index) {
    commit('REMOVE_TRANSIT_BLOCK', index);
  },
  updateTransit({ commit }, { index, field, value }) {
    commit('UPDATE_TRANSIT', { index, field, value });
  },
  clearTransit({ commit }) {
    commit('CLEAR_TRANSIT');
  },
  setActiveUser({ commit }, user) {
    commit('SET_ACTIVE_USER', user);
  },
  setInitiatorUser({ commit }, user) {
    commit('SET_INITIATOR_USER', user);
  },
  setUserCity({ commit }, city) {
    commit('SET_USER_CITY', city);
  },
  setCommonDataField({ commit }, { field, value }) {
    commit('SET_COMMON_DATA_FIELD', { field, value });
  },
  getFreeFund({ commit }, params) {
    this.$API.businessTrip.getFreeFund(params).then((response) => {
      commit('SET_FREE_FUND', response.data);
    });
  },
  getBusinessProcesses({ commit }, params) {
    this.$API.businessTrip.getBusinessProcesses(params).then((response) => {
      commit('SET_BUSINESS_PROCESSES', response.data);
    });
  },
  getCodePrograms({ commit }, params) {
    this.$API.businessTrip.getProgramCodes(params).then((response) => {
      commit('SET_CODE_PROGRAMS', response.data);
    });
  },
  setCityAtTransitAndAccommodation({ state, dispatch }) {
    if (!state.expenses?.cities?.length) {
      return;
    }

    const cityCount = state.expenses.cities.length;

    // ПРОЕЗД
    // добавление проездов, если необходимо
    const transitCount = state.expenses.cities.length + 1; //  проездов на один больше
    if (state.transit.length < transitCount) {
      let transitDiff = transitCount - state.transit.length;
      while (transitDiff) {
        dispatch('addTransit');
        transitDiff -= 1;
      }
    }

    // формирование и заполнение проездов городами
    state.transit.forEach((transitItem, transitIndex) => {
      const isFirst = transitIndex === 0;
      const isLast = transitIndex === cityCount;
      const startPoint = isFirst
        ? state.userCity
        : cloneDeep(state.expenses.cities[transitIndex - 1]);
      const endPoint = isLast
        ? state.userCity
        : cloneDeep(state.expenses.cities[transitIndex]);

      dispatch('updateTransit', {
        index: transitIndex,
        field: 'startPoint',
        value: cloneDeep(startPoint),
      });
      dispatch('updateTransit', {
        index: transitIndex,
        field: 'endPoint',
        value: cloneDeep(endPoint),
      });

      // eslint-disable-next-line no-nested-ternary
      const day = isFirst
        ? state.expenses.commonDayStart
        : isLast
          ? state.expenses.commonDayEnd
          : null;
      dispatch('updateTransit', {
        index: transitIndex,
        field: 'day',
        value: day || null,
      });
    });

    // ПРОЖИВАНИЕ
    // добавление проживаний, если необходимо
    const accommodationCount = state.expenses.cities.length;
    if (state.accommodation.length < accommodationCount) {
      let accommodationDiff = accommodationCount - state.accommodation.length;
      while (accommodationDiff) {
        dispatch('addAccommodation');
        accommodationDiff -= 1;
      }
    }

    // формирование и заполнение проживаний городом
    state.accommodation.forEach((accommodationItem, accommodationIndex) => {
      const isFirst = accommodationIndex === 0;
      const isLast = accommodationIndex === cityCount - 1;
      const expensesDestination = state.expenses.cities[accommodationIndex];

      // добавление города
      dispatch('updateAccommodation', {
        index: accommodationIndex,
        field: 'destination',
        value: cloneDeep(expensesDestination),
      });

      // добавление дат
      const dayStart = isFirst ? state.expenses.commonDayStart : null;
      const dayEnd = isLast ? state.expenses.commonDayEnd : null;

      dispatch('updateAccommodation', {
        index: accommodationIndex,
        field: 'dayStart',
        value: dayStart || null,
      });
      dispatch('updateAccommodation', {
        index: accommodationIndex,
        field: 'dayEnd',
        value: dayEnd || null,
      });
    });
  },
  updateExpensesCity({ commit, dispatch }, { index, city }) {
    commit('UPDATE_EXPENSES_CITY', { index, city });
    dispatch('setCityAtTransitAndAccommodation');
  },
  setStartFirstChange({ state, dispatch }, clearAccommodation) {
    const dateValue = state.expenses.commonDayStart;

    if (state.transit.length) {
      dispatch('updateTransit', {
        index: 0,
        field: 'day',
        value: dateValue,
      });
    }

    if (clearAccommodation) {
      dispatch('clearAccommodation');
    } else if (state.accommodation.length) {
      dispatch('updateAccommodation', {
        index: 0,
        field: 'dayStart',
        value: dateValue,
      });
    }
  },
  setEndFirstChange({ state, dispatch }, clearAccommodation) {
    const dateValue = state.expenses.commonDayEnd;

    if (state.transit.length) {
      const lastTransitInd = state.transit.length - 1;
      dispatch('updateTransit', {
        index: lastTransitInd,
        field: 'day',
        value: dateValue,
      });
    }

    if (clearAccommodation) {
      dispatch('clearAccommodation');
    } else if (state.accommodation.length) {
      const lastAccommodationInd = state.accommodation.length - 1;
      dispatch('updateAccommodation', {
        index: lastAccommodationInd,
        field: 'dayEnd',
        value: dateValue,
      });
    }
  },
  clearAccommodation({ commit }) {
    commit('CLEAR_ACCOMMODATION');
  },
  updateAccommodation({ commit }, { index, field, value }) {
    commit('UPDATE_ACCOMMODATION', { index, field, value });
  },
  addAccommodation({ commit }) {
    commit('ADD_ACCOMMODATION');
  },
  removeAccommodationBlock({ commit }, index) {
    commit('REMOVE_ACCOMMODATION', index);
  },
  clearState({ commit }) {
    commit('CLEAR_STATE');
    commit('CLEAR_EXPENSES');
    commit('CLEAR_TRANSIT');
    commit('CLEAR_ACCOMMODATION');
  },

  // result
  getCommonData(cxt) {
    const value = {};

    // cfd
    const cfd = cxt.getters.cfd.find((i) => i.id === cxt.state.commonData.cfd);
    value.commonDataCfd = {
      code: cfd.code,
      name: `${cfd.code} ${cfd.name}`,
    };

    // articles
    value.commonDataArticles = cxt.getters.articles.find((i) => i.id === cxt.state.commonData.articles);

    // project
    value.project = cxt.state.commonData.project;

    // businessProcess
    const businessProcess = cxt.getters.businessProcesses.find((i) => i.id === cxt.state.commonData.businessProcess);
    value.businessProcess = businessProcess
      ? {
        id: businessProcess.id,
        name: `${businessProcess.id} ${businessProcess.name}`,
      }
      : null;

    // codeProgram

    value.codeProgram = cxt.state.сodePrograms
      ? cxt.state.сodePrograms.find((i) => i.id === cxt.state.commonData.codeProgram)
      : null;

    // phone
    value.phone = cxt.state.commonData.phone;

    // target
    value.commonDataTarget = cxt.getters.targets.find((i) => i.id === cxt.state.commonData.target);

    // details
    value.details = cxt.state.commonData.details;

    // head
    value.head = cxt.state.commonData.head;

    return value;
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
