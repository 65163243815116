export default ($axios) => ({
  getUserTemplates() {
    return $axios
      .get('/user-templates')
      .then((res) => res);
  },

  addUserTemplate(data = null) {
    return $axios
      .post('/user-templates', data)
      .then((res) => res);
  },

  getUserTemplate(id) {
    return $axios
      .get(`/user-templates/${id}`)
      .then((res) => res);
  },

  updateUserTemplateName(id, name = null) {
    const props = { name };

    return $axios
      .put(`/user-templates/${id}`, props)
      .then((res) => res);
  },

  deleteUserTemplate(id) {
    return $axios
      .delete(`/user-templates/${id}`)
      .then((res) => res);
  },

  updateUserTemplates(data = null) {
    return $axios
      .patch('/user-templates', data)
      .then((res) => res);
  },
});
