export default ($axios) => ({
  // Получение списка популярных услуг
  getPopularServices(params = { page: 0, size: 20 }) {
    return $axios
      .get('/admin/services/popular', { params })
      .then((res) => res);
  },
  // Добавление популярной услуги в списком
  addPopularService(id, subscriptions, sourceSystem = 'otrs1') {
    return $axios
      .post('/admin/services/popular', { id, subscriptions, sourceSystem })
      .then((res) => res);
  },
  // Удаление популярной услуги из списка
  removePopularService(id) {
    return $axios
      .delete(`/admin/services/${id}/popular/`)
      .then((res) => res);
  },
  // Поиск по популярным услугам
  searchPopularServices(query) {
    return $axios
      .get('/admin/services/popular/simple-search', { params: { query } })
      .then((res) => res);
  },
  // Обновление порядка популярных услуг
  updatePopularServices(list) {
    return $axios
      .patch('/admin/services/popular', list)
      .then((res) => res);
  },
  // Добавление / обновление подписок на популярные услуги
  updateSubscriptions(id, subscriptions, sourceSystem = 'otrs1') {
    return $axios
      .patch('/admin/services/popular', { id, subscriptions, sourceSystem })
      .then((res) => res);
  },
  // Получение детальной инфорации о подписках
  getSubscriptions(serviceId) {
    return $axios
      .get(`/admin/services/${serviceId}/popular/subscription-structure`)
      .then((res) => res);
  },
});
