import dayjs from 'dayjs';
import { getSearchCodeByTypeId } from '@/constants/search';

const isValidTicket = (filter, ticket) => {
  if (filter.client && filter.client.login) {
    if (ticket.customer.fullName !== filter.client.fullName) return false;
  }

  if (filter.service && filter.service.id) {
    if (ticket.service !== filter.service.name) return false;
  }

  if (filter.dateStart) {
    if (dayjs(ticket.createdAt).valueOf() < dayjs(filter.dateStart).valueOf()) return false;
  }

  if (filter.dateEnd) {
    if (dayjs(ticket.createdAt).valueOf() > dayjs(filter.dateEnd).valueOf()) return false;
  }

  return true;
};

const state = {
  query: '',
  services: null,
  tickets: null,
  faqArticles: [],
  type: 1,
  ticketFilter: {
    client: null,
    service: null,
    dateStart: null,
    dateEnd: null,
  },
  searchLogId: null,
  loading: false,
  results: [],
};

const getters = {
  filteredTickets: (state) => {
    if (state.tickets && state.tickets.length) {
      return state.tickets.filter((ticket) => isValidTicket(state.ticketFilter, ticket));
    }
    return null;
  },

  activeSearchTypeCode: (state) => getSearchCodeByTypeId(state.type),
};

const mutations = {
  clearSearchData(state) {
    state.services = null;
    state.tickets = null;
    state.faqArticles = [];
  },
  setServices(state, payload) {
    state.tickets = null;
    state.services = payload;
  },
  setTickets(state, payload) {
    state.services = null;
    state.tickets = payload;
  },
  setFaqArticles(state, payload) {
    state.faqArticles = payload;
  },
  setType(state, payload) {
    state.type = payload;
  },
  setTicketFilter(state, payload) {
    state.ticketFilter = payload;
  },
  setQuery(state, payload) {
    state.query = payload;
  },
  setSearchLogId(state, payload) {
    state.searchLogId = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  SET_RESULTS(state, data) {
    state.results = data;
  },
};

const actions = {
  async search({
    commit, state, rootState, dispatch,
  }, query) {
    // TODO: переделать запросы (заявки, услуги, faq) на паджинацию, когда бек переделает
    const searchServices = async () => {
      try {
        const {
          services,
          searchLogId,
        } = await this.$API.services.smartSearchExact(
          query,
          {
            allowedOnly: true,
            userInfoByMasterSystems: rootState.user.selectedUser?.userInfoByMasterSystems,
          },
        );

        dispatch('setSearchLogId', searchLogId);
        await commit('setServices', services);

        return Promise.resolve(services);
      } catch (e) {
        return Promise.reject(e);
      }
    };

    const searchTickets = async () => {
      try {
        const tickets = await this.$API.ticket.searchTickets(query);

        await commit('setTickets', tickets);

        return Promise.resolve(tickets);
      } catch (e) {
        return Promise.reject(e);
      }
    };

    const searchFaqArticles = async () => {
      // используется пока максимальное кол-во итемов на страницу
      try {
        const {
          content: articles,
        } = await this.$API.faq.searchArticles(
          query,
          {
            size: 100,
          },
        );

        await commit('setFaqArticles', articles);

        return Promise.resolve(articles);
      } catch (e) {
        return Promise.reject(e);
      }
    };

    switch (state.type) {
    case 1:
      dispatch('setLoading', true);
      searchFaqArticles();
      await searchServices();
      dispatch('setLoading', false);
      break;

    case 2:
      dispatch('setLoading', true);
      await searchTickets();
      dispatch('setLoading', false);
      break;

    case 3:
      dispatch('setLoading', true);
      await searchFaqArticles();
      dispatch('setLoading', false);
      break;

    default:
      commit('clearSearch');
    }
  },

  setType({ commit }, state) {
    commit('setType', state);
  },

  setTicketFilter({ commit }, state) {
    commit('setTicketFilter', state);
  },

  setQuery({ commit }, state) {
    commit('setQuery', state);
  },

  clearSearchDataAndQuery({ commit }) {
    commit('clearSearchData');
    commit('setQuery', '');
  },

  clearSearchData({ commit }) {
    commit('clearSearchData');
  },

  setSearchLogId({ commit }, payload) {
    commit('setSearchLogId', payload);
  },

  clearSearchLogId({ commit }) {
    commit('setSearchLogId', null);
  },

  setLoading({ commit }, payload) {
    commit('setLoading', payload);
  },
  setResults({ commit }, data) {
    commit('SET_RESULTS', data);
  },
};

const search = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default search;
