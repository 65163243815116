<template>
  <component :is="layout" />
</template>

<script>
export default {
  name: 'LayoutDynamic',
  props: {
    path: {
      type: String,
      default: 'layouts',
    },
  },
  data() {
    return {
      layoutName: 'LayoutDefault',
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const newLayout = to.meta.layout;
        this.layoutName = newLayout || 'LayoutDefault';
      },
    },
  },
  computed: {
    layout() {
      const fullPath = `${this.path}/${this.layoutName}`;
      return () => import(/* webpackChunkName: "[request]" */ `@/${fullPath}.vue`);
    },
  },
};
</script>
