import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import update from 'lodash/update';

const state = {
  originSettings: null,
  settings: null,
};

const getters = {};

const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = cloneDeep(settings);
    state.originSettings = Object.freeze(settings);
  },
  SET_SETTING(state, { path, value }) {
    const settings = cloneDeep(state.settings);
    update(settings, path.join('.'), () => value);
    Vue.set(state, 'settings', settings);
  },
};

const actions = {
  getSettings({ commit }) {
    return this.$API.portalStyle.getSettingsUI().then(async ({ data }) => {
      commit('SET_SETTINGS', data);
    });
  },
  setSetting({ commit }, { path, value }) {
    commit('SET_SETTING', { path, value });
  },
  saveAllSettings({ state, commit }, dto) {
    return this.$API.portalStyle.setSettingsUI(dto || state.settings).then(({ data }) => {
      commit('SET_SETTINGS', data);
    });
  },
  saveSettings({ state, dispatch }, { group, settingList = [] }) {
    const dto = cloneDeep(state.originSettings);
    settingList.forEach((s) => {
      if (state.settings[group][s]) {
        dto[group][s] = state.settings[group][s];
      }
    });

    dispatch('saveAllSettings', dto);
  },
  setDefault({ commit }, section) {
    return this.$API.portalStyle.getDefaultSettingsUI(section).then(({ data }) => {
      commit('SET_SETTINGS', data);
    });
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
