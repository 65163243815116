import responseErrorInterceptor from '@/plugins/axios/responseErrorInterceptor';
import httpClient from '@/plugins/axios/httpClient';
import Queuer from '@/plugins/Queuer';

httpClient.create();

const requestQueue = new Queuer();

httpClient.instance.interceptors.request.use((config) => new Promise(((resolve, reject) => {
  try {
    const key = config.baseURL + config.url + JSON.stringify(config.data);
    requestQueue.call(key, () => resolve(config));
  } catch (e) {
    reject(e);
  }
})));

httpClient.instance.interceptors.response.use(
  (res) => res,
  (error) => responseErrorInterceptor(error),
);

export default httpClient;
