import localStorageDb from '@/utils/localStorageDB';
import { WINDOW_SIZES, VIEW_MODE } from '@/constants/view-options';

const state = {
  windowSizes: {
    isDesktop: true,
    isTabletLandscape: false,
    isTabletPortrait: false,
    isPhoneLandscape: false,
    isPhonePortrait: false,
    current: 0,
  },
  showLayoutBannerLinks: localStorageDb.get('showLayoutBannerLinks') || false,
  viewMode: VIEW_MODE.regular,
};

const getters = {
  isInterfaceHidden: (state) => state.viewMode === VIEW_MODE.onlyForm,
};

const mutations = {
  SET_WINDOW_SIZES(state, windowSize) {
    state.windowSizes.current = windowSize;
    state.windowSizes.isDesktop = (windowSize >= WINDOW_SIZES.desktop);
    state.windowSizes.isTabletLandscape = (windowSize < WINDOW_SIZES.desktop);
    state.windowSizes.isTabletPortrait = (windowSize < WINDOW_SIZES.tabletLandscape);
    state.windowSizes.isPhoneLandscape = (windowSize < WINDOW_SIZES.tabletPortrait);
    state.windowSizes.isPhonePortrait = (windowSize < WINDOW_SIZES.phone);
  },
  SET_SHOW_LAYOUT_BANNER_LINKS(state, showLayoutBannerLinks) {
    state.showLayoutBannerLinks = showLayoutBannerLinks;
    localStorageDb.put('showLayoutBannerLinks', showLayoutBannerLinks);
  },
  SET_VIEW_MODE(state, viewMode) {
    state.viewMode = viewMode;
  },
};

const actions = {
  async setWindowSizes({ commit, state, dispatch }, windowSize) {
    await commit('SET_WINDOW_SIZES', windowSize);
    if (state.windowSizes.isTabletPortrait) {
      dispatch('sidebar/collapseMenu', true, { root: true });
    }
  },
  setShowLayoutBannerLinks({ commit }, showLayoutBannerLinks) {
    commit('SET_SHOW_LAYOUT_BANNER_LINKS', showLayoutBannerLinks);
  },
  setViewMode({ commit, state }, viewMode = VIEW_MODE.regular) {
    if (state.viewMode !== viewMode) {
      commit('SET_VIEW_MODE', viewMode);
    }
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
