import cloneDeep from 'lodash/cloneDeep';
import { TREE_LEVELS } from '@/constants/ticket';

const state = {
  // TODO: хранить исчисляемые элементы в виде пустых массивов
  favoriteServices: null,
  popularServices: null,
  serviceTypes: [],
  serviceCategories: [],
  serviceGroups: [],
  services: [],
};

const getters = {
  sortedServices: (state) => {
    if (state.services.length) {
      return [...state.services].sort((a, b) => a.name.localeCompare(b.name));
    } return [];
  },
};

const mutations = {
  SET_FAVORITE_SERVICE(state, val) {
    state.favoriteServices = val;
  },
  CACHE_SERVICE_TYPES(state, types) {
    state.serviceTypes = types.map(type => ({
      id: type.name,
      name: type.name,
    }));
  },
  CACHE_SERVICE_CATEGORY(state, categories) {
    state.serviceCategories = categories.map((categorie) => ({
      id: categorie.name,
      name: categorie.name,
    }));
  },
  CACHE_SERVICE_GROUP(state, groups) {
    state.serviceGroups = groups.map((group) => ({
      id: group.name,
      name: group.name,
    }));
  },
  CACHE_SERVICES(state, services) {
    state.services = services.map((service) => ({
      id: service.id,
      name: service.name,
      source: service.sourceSystem,
    }));
  },
  SET_POPULAR_SERVICE(state, val) {
    state.popularServices = val;
  },
};

const actions = {
  updateFavorites({ commit }, data) {
    commit('SET_FAVORITE_SERVICE', data);
  },
  getFavorites({ commit }) {
    return this.$API.services.getFavorites().then(({ data }) => {
      commit('SET_FAVORITE_SERVICE', data);
    }).catch((e) => {
      commit('SET_FAVORITE_SERVICE', null);
      return e;
    });
  },

  async addToFavorite({ dispatch, state }, { serviceId, sourceSystem, serviceName }) {
    try {
      const { data: service } = await this.$API.services.addFavorites({ serviceId, serviceName, sourceSystem });

      dispatch('updateFavorites', [...state.favoriteServices, service]);

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async deleteFavorites({ dispatch, state }, { serviceId, serviceName, sourceSystem }) {
    try {
      await this.$API.services.deleteFavorites({ serviceId, serviceName, sourceSystem });

      dispatch('updateFavorites', [...state.favoriteServices.filter((el) => el.id !== serviceId)]);

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  updateSortOrderFavorites({ dispatch }, services) {
    const oldValue = cloneDeep(state.favoriteServices);

    dispatch('updateFavorites', services);
    this.$API.services.updateFavorites(services)
      .catch((e) => {
        dispatch('updateFavorites', oldValue);
        throw new Error(e);
      });
  },

  fetchServiceTypes(context) {
    context.dispatch('system/setLoading', { key: 'page', value: true }, { root: true });

    const params = {};
    if (context.rootState.user && context.rootState.user.selectedUser) {
      params.userInfoByMasterSystems = context.rootState.user.selectedUser.userInfoByMasterSystems;
    }

    return this.$API.services.getListByStructure(params).then(({ data }) => {
      context.commit('CACHE_SERVICE_TYPES', data);
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      return data;
    }).catch((Error) => {
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      const { error } = Error.response.data;
      throw error;
    });
  },
  fetchStructure(context, { serviceId }) {
    context.dispatch('system/setLoading', { key: 'page', value: true }, { root: true });

    const { selectedUser } = context.rootState.user;
    const body = {};
    if (selectedUser) {
      Object.assign(body, {
        userInfoByMasterSystems: selectedUser.userInfoByMasterSystems,
      });
    }

    return this.$API.services.getListByStructureId(serviceId, body).then(async ({ data }) => {
      context.commit('CACHE_SERVICE_TYPES', data.types);
      context.commit('CACHE_SERVICE_CATEGORY', data.categories);
      context.commit('CACHE_SERVICE_GROUP', data.groups);
      context.commit('CACHE_SERVICES', data.services);
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      const newTree = [
        {
          level: TREE_LEVELS.indexOf('firstLevel'),
          value: {
            name: data.currentTypeName,
            id: data.currentTypeName,
          },
        },
        {
          level: TREE_LEVELS.indexOf('secondLevel'),
          value: {
            name: data.currentCategoryName,
            id: data.currentCategoryName,
          },
        },
        {
          level: TREE_LEVELS.indexOf('thirdLevel'),
          value: {
            name: data.currentGroupName,
            id: data.currentGroupName,
          },
        },
        {
          level: TREE_LEVELS.indexOf('fourthLevel'),
          value: {
            name: data.currentServiceName,
            id: data.currentServiceName,
          },
        },
      ];
      await context.dispatch('ticket/setTreeElement', newTree, { root: true });
    }).catch((Error) => {
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      const { error } = Error.response.data;
      throw error;
    });
  },
  fetchServiceCategory(context) {
    context.dispatch('system/setLoading', { key: 'page', value: true }, { root: true });
    context.commit('CACHE_SERVICE_CATEGORY', []);
    context.commit('CACHE_SERVICE_GROUP', []);
    context.commit('CACHE_SERVICES', []);
    const firstLevelService = context.rootState.ticket.tree.firstLevel;

    const params = {};
    // eslint-disable-next-line max-len
    if (firstLevelService && firstLevelService.name) params.typeName = firstLevelService.name;
    if (context.rootState.user && context.rootState.user.selectedUser) {
      params.userInfoByMasterSystems = context.rootState.user.selectedUser.userInfoByMasterSystems;
    }

    return this.$API.services.getListByStructure(params).then(({ data }) => {
      context.commit('CACHE_SERVICE_CATEGORY', data);
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      return data;
    }).catch((Error) => {
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      const { error } = Error.response.data;
      throw error;
    });
  },
  fetchServiceGroup(context) {
    context.dispatch('system/setLoading', { key: 'page', value: true }, { root: true });
    context.commit('CACHE_SERVICE_GROUP', []);
    context.commit('CACHE_SERVICES', []);
    const firstLevelService = context.rootState.ticket.tree.firstLevel;
    const secondLevelService = context.rootState.ticket.tree.secondLevel;
    const params = {
      typeName: firstLevelService.name,
      categoryName: secondLevelService.name,
    };
    if (context.rootState.user && context.rootState.user.selectedUser) {
      params.userInfoByMasterSystems = context.rootState.user.selectedUser.userInfoByMasterSystems;
    }

    return this.$API.services.getListByStructure(params).then(({ data }) => {
      context.commit('CACHE_SERVICE_GROUP', data);
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      return data;
    }).catch((Error) => {
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      const { error } = Error.response.data;
      throw error;
    });
  },
  fetchService(context) {
    context.dispatch('system/setLoading', { key: 'page', value: true }, { root: true });
    context.commit('CACHE_SERVICES', []);
    const firstLevelService = context.rootState.ticket.tree.firstLevel;
    const secondLevelService = context.rootState.ticket.tree.secondLevel;
    const thirdLevelService = context.rootState.ticket.tree.thirdLevel;

    const params = {
      typeName: firstLevelService.name,
      categoryName: secondLevelService.name,
      groupName: thirdLevelService.name,
    };
    if (context.rootState.user && context.rootState.user.selectedUser) {
      params.userInfoByMasterSystems = context.rootState.user.selectedUser.userInfoByMasterSystems;
    }

    return this.$API.services.getListByStructure(params).then(({ data }) => {
      context.commit('CACHE_SERVICES', data);
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      return data;
    }).catch((Error) => {
      context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      const { error } = Error.response.data;
      throw error;
    });
  },
  clearServices(context) {
    context.commit('CACHE_SERVICE_CATEGORY', []);
    context.commit('CACHE_SERVICE_GROUP', []);
    context.commit('CACHE_SERVICES', []);
  },
  getPopular({ commit }) {
    return this.$API.services.getPopular().then(({ data }) => {
      commit('SET_POPULAR_SERVICE', data.content);
    }).catch((e) => {
      commit('SET_POPULAR_SERVICE', null);
      return e;
    });
  },
};

const services = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default services;
