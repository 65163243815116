export const SEARCH_LABELS = {
  tickets: 'По заявкам',
  services: 'По услугам',
  faq: 'По базе знаний',
};

export const SEARCH_TYPES = [
  {
    id: 1,
    label: SEARCH_LABELS.services,
  },
  {
    id: 2,
    label: SEARCH_LABELS.tickets,
  },
  {
    id: 3,
    label: SEARCH_LABELS.faq,
  },
];

export const getSearchTypeByLabel = (label) => SEARCH_TYPES
  .find((item) => item.label === label);

export const getSearchCodeByTypeId = (id) => {
  const typeLabel = SEARCH_TYPES
    .find((item) => item.id === id)?.label;

  return typeLabel
    ? Object.keys(SEARCH_LABELS).find((key) => SEARCH_LABELS[key] === typeLabel)
    : null;
};

// TODO: сделать глобальной переменной (не в Search), назвать типа "debounce delay"
export const SEARCH_DELAY = 800;

export const SEARCH_RESULTS_PER_PAGE = 10;

export const MIN_QUERY_LENGTH = 3;
