<script>
import EsmpUI from '@esmpfrontend/ui';
import { WINDOW_SIZES } from '@/constants/view-options';

const MAX_PAGE = 6;
const MAX_PAGE_MOBILE = 4;

export default {
  name: 'EsmpPaginationAdaptive',
  functional: true,
  render(h, ctx) {
    return h(
      EsmpUI.EsmpPagination,
      {
        ...ctx.data,
        props: {
          // eslint-disable-next-line max-len
          pagerCount: window.innerWidth < WINDOW_SIZES.tabletPortrait ? MAX_PAGE_MOBILE : ctx.props.pagerCount || MAX_PAGE,
        },
      },
      ctx.children,
    );
  },
};
</script>
