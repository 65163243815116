import Vue from 'vue';
import { icons as esmpUIIcons } from '@esmpfrontend/ui';
import esmpSvgIconsPath from '@esmpfrontend/ui/dist/images/icons.svg';

const projectIcons = {};
const req = require.context('../assets/icons/', false, /\.svg$/);
const requireAll = (requireContext) => requireContext.keys().map(requireContext);
const commonRequiredIcons = requireAll(req);

commonRequiredIcons.forEach((item) => {
  const icon = {
    id: item.default.id.replace('-usage', ''),
    url: item.default.url,
    viewBox: item.default.viewBox,
  };

  projectIcons[icon.id] = icon;
});

/*
  Обновление url иконок из EsmpUI, так как Webpack
  импортирует спрайт иконок EsmpUI в проект и меняет его путь
 */
const mappedEsmpUIIcons = esmpUIIcons;
Object.keys(esmpUIIcons).forEach((iconName) => {
  mappedEsmpUIIcons[iconName] = {
    ...esmpUIIcons[iconName],
    url: `${esmpSvgIconsPath}#${esmpUIIcons[iconName].id}`,
  };
});

Vue.prototype.$svgIcons = { ...projectIcons, ...mappedEsmpUIIcons };
