const state = {
  delegations: [],
};

const getters = {};

const mutations = {
  SET_DELEGATIONS(state, delegations) {
    state.delegations = delegations.sort((a, b) => b.dateFrom - a.dateFrom);
  },
};

const actions = {
  getDelegations({ commit }) {
    return this.$API.delegation.getDelegationList().then(({ data }) => {
      commit('SET_DELEGATIONS', data);
    }).catch((e) => e);
  },
};

const delegations = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default delegations;
