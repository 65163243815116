import axios from 'axios';
import { baseURL } from '@/constants/api';

const isAbortControllerSupported = !!window.AbortController;

const httpClient = {
  /**
   * Инстанс http-клиента
   */
  instance: null,
  instanceWithoutAuth: null,

  /**
   * Контроллер для http-клиента.
   * Использовать если нужно прервать запросы.
   */
  abortController: null,

  /**
   * Инициализация клиента
   */
  create() {
    this.abortController = isAbortControllerSupported ? new AbortController() : null;

    if (this.instance && isAbortControllerSupported) {
      this.instance.defaults.signal = this.abortController.signal;
    }

    this.instance = axios.create({
      baseURL,
      withCredentials: true,
      signal: isAbortControllerSupported ? this.abortController.signal : null,
    });
    this.instanceWithoutAuth = axios.create({
      baseURL,
      withCredentials: true,
    });
  },

  /**
   * Перезапуск http-клиента.
   * Нужно, например, для того, чтобы AbortSignal обновился.
   * @param time {number} - время в милисекундах
   */
  reload(time = 0) {
    setTimeout(() => {
      this.create();
    }, time);
  },
};

export default httpClient;
