export default ($axios) => ({
  getSurveys() {
    return $axios
      .get('/survey/forms')
      .then((res) => res);
  },
  createSurvey(dto) {
    return $axios
      .post('/survey/form', dto)
      .then((res) => res);
  },
  getSurvey(id) {
    return $axios
      .get(`/survey/form/${id}`)
      .then((res) => res);
  },
  updateSurvey(dto) {
    return $axios
      .put('/survey/form', dto)
      .then((res) => res);
  },
  deleteSurvey(id) {
    return $axios
      .delete(`/survey/form/${id}`)
      .then((res) => res);
  },
  getSurveyChecklists() {
    return $axios
      .get('/survey/checklists')
      .then((res) => res);
  },
  getSurveyChecklist(id) {
    return $axios
      .get(`/survey/checklist/${id}`)
      .then((res) => res);
  },
  getSurveyChecklistTemplate(id) {
    return $axios
      .get(`/survey/checklist/completed/${id}`)
      .then((res) => res);
  },
  save(data = null) {
    return $axios
      .post('/survey/checklist', data)
      .then((res) => res);
  },
  finish(data = null) {
    return $axios
      .post('/survey/checklist/finish', data)
      .then((res) => res);
  },
  // Получение списка отчётов
  getSurveyReports(formId, size, page) {
    return $axios
      .get(`/survey/forms/${formId}/reports?size=${size}&page=${page}&sort=id,desc`)
      .then((res) => res);
  },
  // Создание отчёта
  createSurveyReport(formId) {
    return $axios
      .post(`/survey/forms/${formId}/report`)
      .then((res) => res);
  },
  // Получить список возможных приоритетов опроса
  getSurveyPriorities() {
    return $axios
      .get('/survey/priorities')
      .then((res) => res);
  },
  // Доп инфа по подпискам базовых опросов
  getSurveySubscription(formId) {
    return $axios
      .get(`/survey/subscription-structure/${formId}`)
      .then((res) => res);
  },
});
