import Vue from 'vue';
import localStorageDb from '@/utils/localStorageDB';
import {
  TREE_LEVELS,
  VIEW_TYPES,
  TICKETS_PER_PAGE_LIST,
  SORT_TYPES,
} from '@/constants/ticket';

const state = {
  tickets: null,
  counters: {},
  customServiceFiles: [],
  tree: {
    firstLevel: null,
    secondLevel: null,
    thirdLevel: null,
    fourthLevel: null,
  },
  activeView: localStorageDb.get('activeView') || VIEW_TYPES[0],
  ticketsPerPage: localStorageDb.get('ticketsPerPage') || TICKETS_PER_PAGE_LIST[0],
  ticketSortType: localStorageDb.get('ticketSortType') || SORT_TYPES[0],
  approvalSortType: localStorageDb.get('approvalSortType') || SORT_TYPES[0],
  form: null,
  filesForSend: [],
};

const getters = {
  currentTypeName: (state) => state.tree?.firstLevel?.name,
  currentCategoryName: (state) => state.tree?.secondLevel?.name,
  currentGroupName: (state) => state.tree?.thirdLevel?.name,
  currentServiceName: (state) => state.tree?.fourthLevel?.name,
};

const mutations = {
  SET_TICKETS(state, val) {
    state.tickets = val;
  },
  ADD_TICKETS(state, val) {
    state.tickets = state.tickets.concat(val);
  },
  SET_COUNTERS(state, val) {
    state.counters = val;
  },
  SET_CUSTOM_SERVICE_FILES(state, payload) {
    state.customServiceFiles = payload;
  },
  CLEAR_CUSTOM_SERVICE_FILES(state) {
    state.customServiceFiles = [];
  },
  SET_TREE_ELEMENT(state, { level, value }) {
    if (level < 0) return;
    const treeLevel = TREE_LEVELS[level];
    for (let i = level; i < TREE_LEVELS.length; i += 1) {
      Vue.set(state.tree, TREE_LEVELS[i], {});
    }
    Vue.set(state.tree, treeLevel, value);
  },
  SET_ACTIVE_VIEW(state, activeView) {
    state.activeView = activeView;
    localStorageDb.put('activeView', activeView);
  },
  SET_TICKETS_PER_PAGE(state, ticketsPerPage) {
    state.ticketsPerPage = ticketsPerPage;
    localStorageDb.put('ticketsPerPage', ticketsPerPage);
  },
  SET_TICKETS_SORT_TYPE(state, ticketSortType) {
    state.ticketSortType = ticketSortType;
    localStorageDb.put('ticketSortType', ticketSortType);
  },
  SET_APPROVAL_SORT_TYPE(state, approvalSortType) {
    state.approvalSortType = approvalSortType;
    localStorageDb.put('approvalSortType', approvalSortType);
  },
  SET_FORM(state, form) {
    state.form = form;
  },
  SET_FILES_FOR_SEND(state, filesForSend) {
    state.filesForSend = filesForSend;
  },
};

const actions = {
  getTickets({ commit }, {
    sortBy = 'newup',
    category = 'active',
    offset = 0,
    limit = 20,
    isMore = false,
    isActive = true,
  }) {
    return this.$API.ticket.getTicketList({
      sortBy,
      category,
      offset,
      limit,
      isActive,
    }).then(({ data }) => {
      if (isMore) {
        commit('ADD_TICKETS', data);
      } else {
        commit('SET_TICKETS', data);
      }
      return data;
    }).catch((e) => {
      commit('SET_TICKETS', []);
      return e;
    });
  },
  fetchCounters({ commit }) {
    return this.$API.ticket.getTicketCounters(
    ).then(({ data }) => {
      commit('SET_COUNTERS', data);
    }).catch((e) => {
      commit('SET_COUNTERS', []);
      return e;
    });
  },
  setCustomServiceFiles({ commit }, files) {
    commit('SET_CUSTOM_SERVICE_FILES', files);
  },
  clearCustomServiceFiles({ commit }) {
    commit('CLEAR_CUSTOM_SERVICE_FILES');
  },
  setTreeElement({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        if (Array.isArray(payload)) {
          payload.forEach(({ level, value }) => commit('SET_TREE_ELEMENT', { level, value }));
        } else {
          const { level, value } = payload;
          commit('SET_TREE_ELEMENT', { level, value });
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },
  fetchUserAdditionalInfo(context, employeeLogin) {
    context.dispatch('system/setLoading', { key: 'page', value: true }, { root: true });
    return this.$API.user.info(employeeLogin)
      .then(({ data }) => data)
      .catch(() => null)
      .finally(() => {
        context.dispatch('system/setLoading', { key: 'page', value: false }, { root: true });
      });
  },
  setActiveView({ commit }, activeView) {
    commit('SET_ACTIVE_VIEW', activeView);
  },
  setTicketsPerPage({ commit }, ticketsPerPage) {
    commit('SET_TICKETS_PER_PAGE', ticketsPerPage);
  },
  setTicketSortType({ commit }, ticketSortType) {
    commit('SET_TICKETS_SORT_TYPE', ticketSortType);
  },
  setApprovalSortType({ commit }, approvalSortType) {
    commit('SET_APPROVAL_SORT_TYPE', approvalSortType);
  },
  setForm({ commit }, form) {
    commit('SET_FORM', form);
  },
  setFilesForSend({ commit }, filesForSend) {
    commit('SET_FILES_FOR_SEND', filesForSend);
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
