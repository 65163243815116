import { baseURL } from '@/constants/api';
import appConfig from '@/app.config';
import router from '@/router';
import { isLoginPage, isLogoutPage } from '@/helpers/url-path.helper';

export default {
  namespaced: true,

  actions: {
    // OIDC Auth
    async loginOIDC() {
      window.location.href = `${baseURL}/${appConfig.auth.url}?redirectUri=${window.location.href}`;

      return Promise.resolve();
    },

    logoutOIDC({ dispatch }) {
      dispatch('loginOIDC');
    },

    // Basic Auth
    async loginBasic(_, { username, password }) {
      return this.$API.system.auth({ username, password }).then(() => true);
    },

    async logoutBasic() {
      const prevPath = `${window.location.pathname}${window.location.search}`;

      if (isLoginPage(prevPath)) {
        return Promise.reject();
      }

      // Не позволяет вернуть после логина на страницу логаута
      const query = !isLogoutPage(prevPath)
        ? { redirectFrom: prevPath }
        : null;

      await router.push({ name: 'Login', query });

      return Promise.resolve();
    },

    // Base interface
    login({ dispatch }, { username, password } = {}) {
      return appConfig.auth.oidc
        ? dispatch('loginOIDC')
        : dispatch('loginBasic', { username, password });
    },

    async logout({ dispatch }) {
      dispatch('user/clearUser', null, { root: true });

      if (appConfig.auth.oidc) {
        dispatch('logoutOIDC');
      } else {
        dispatch('logoutBasic');
      }

      dispatch('user/setIsUserLoggedIn', false, { root: true });
      dispatch('system/setLoading', { key: 'app', value: false }, { root: true });

      return this.$API.system.logout();
    },
  },
};
