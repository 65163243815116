export default ($axios) => ({
  /**
   * Авторизация для iframe метрики. При успехе проставляется токен в куки.
   * Учетка только одна
   */
  auth() {
    return $axios
      .post('https://demobox7.rt.ru/api/Authentication/LocalUserAuth', {
        Login: 'HelpMe',
        Password: 'HelpMe',
      })
      .then((res) => res);
  },
});
