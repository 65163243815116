export default ($axios) => ({
  /**
   * Получение общих настроек приложения
   * @return {Promise<object>}
   */
  getAppSettings() {
    return $axios
      .get('/app-settings')
      .then((res) => res);
  },
  auth(data = null) {
    return $axios
      .post('/auth', data)
      .then((res) => res);
  },
  logout() {
    return $axios
      .get('/auth/logout')
      .then((res) => res);
  },
});
