export default ($axios) => ({
  getUserWorkPlaceInfo(params = { email: undefined }) {
    return $axios
      .get('/employee-replace/employee-work-place', { params })
      .then((res) => res);
  },
  getFieldsByWorkPlaceType(params = {
    workPlaceTypeFrom: undefined,
    workPlaceTypeTo: undefined,
  }) {
    return $axios
      .get('/employee-replace/fields', { params })
      .then((res) => res);
  },

  // TODO: этот запрос отправляется несколько раз подряд
  searchWorkPlacesInfo(data = null) {
    return $axios
      .post('/employee-replace/search-work-place', data)
      .then((res) => res);
  },
});
