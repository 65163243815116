export const state = {
  valuesModelRu: {},
  valuesModelEn: {},
};

export const getters = {};

export const mutations = {
  SET_VALUES_MODEL(state, { lang, value }) {
    state[`valuesModel${lang}`] = value;
  },
};

export const actions = {
  getBusinessCardTranslation({}, { position, address }) {
    return this.$API.user.translationInfo({
      positionRus: position || '',
      address: address || '',
    })
      .then((response) => response.data)
      .catch((err) => {
        throw err;
      });
  },
  setValuesModel({ commit }, { lang, value }) {
    commit('SET_VALUES_MODEL', { lang, value });
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
