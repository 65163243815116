<script>
import EsmpUI from '@esmpfrontend/ui';
import { WINDOW_SIZES } from '@/constants/view-options';

export default {
  name: 'EsmpDatepickerAdaptive',
  functional: true,
  render(h, ctx) {
    return h(
      EsmpUI.EsmpDatepicker,
      {
        ...ctx.data,
        props: {
          isNativePickerEnabled: window.innerWidth < WINDOW_SIZES.tabletPortrait,
        },
      },
      ctx.children,
    );
  },
};
</script>
