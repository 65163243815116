export const ROLE_ACTION_TYPE = {
  ADD_ROLE: 'addRole',
  REMOVE_ROLE: 'removeRole',
};

export const USER_ROLES_TYPES = {
  USER: 'ROLE_USER',
  SUPER: 'ROLE_SUPER',
  BIB_EMPLOYEE: 'ROLE_BIB_EMPLOYEE',
  BANNED: 'ROLE_BANNED',
  DZO_EMPLOYEE: 'ROLE_DZO_EMPLOYEE',
  ADMIN: 'ROLE_ADMIN',
  PRIVILEGED_USER: 'ROLE_PRIVILEGED_USER',
  DEVELOPER: 'ROLE_DEVELOPER',
  EXTERNAL_SYSTEM: 'ROLE_EXTERNAL_SYSTEM',
};

export const USER_ROLES = {
  [USER_ROLES_TYPES.USER]: {
    code: USER_ROLES_TYPES.USER,
    name: 'Пользователь',
  },
  [USER_ROLES_TYPES.SUPER]: {
    code: USER_ROLES_TYPES.SUPER,
    name: 'Супер администратор',
  },
  [USER_ROLES_TYPES.BIB_EMPLOYEE]: {
    code: USER_ROLES_TYPES.BIB_EMPLOYEE,
    name: 'Сотрудник БИБ',
  },
  [USER_ROLES_TYPES.BANNED]: {
    code: USER_ROLES_TYPES.BANNED,
    name: 'Заблокированный',
  },
  [USER_ROLES_TYPES.DZO_EMPLOYEE]: {
    code: USER_ROLES_TYPES.DZO_EMPLOYEE,
    name: 'Сотрудник ДЗО',
  },
  [USER_ROLES_TYPES.ADMIN]: {
    code: USER_ROLES_TYPES.ADMIN,
    name: 'Администратор',
  },
  [USER_ROLES_TYPES.PRIVILEGED_USER]: {
    code: USER_ROLES_TYPES.PRIVILEGED_USER,
    name: 'Привилегированный пользователь',
  },
  [USER_ROLES_TYPES.DEVELOPER]: {
    code: USER_ROLES_TYPES.DEVELOPER,
    name: 'Разработчик',
  },
  [USER_ROLES_TYPES.EXTERNAL_SYSTEM]: {
    code: USER_ROLES_TYPES.EXTERNAL_SYSTEM,
    name: 'Внешний пользователь',
  },
};
