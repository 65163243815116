import { FAQ_ARTICLES_COUNT_PER_PAGE, FAQ_SORT_TYPES } from '@/constants/faq-page';

export default ($axios) => ({
  /**
   * Получение информации о категории Базы Знаний (FAQ)
   * @link https://ci-01-otrs.rtk-dc.ru/helpme/helpme-docs/-/wikis/FAQ/Получение-содержимого-категории
   * @link http://dev-tbkrv.ks.rt.ru:8080/swagger-ui.html#/FAQ%20operations
   * @param categoryId {number} - id категории, по умолчанию корневая с id 0
   * @param articlesPageSize {number} - кол-во статей категории, которые нужно прислать
   * @param articleSortBy {string} - тип сортировки статей
   * @return {Promise<object>}
   */
  getCategoryInfo(
    categoryId = 0,
    articlesPageSize = FAQ_ARTICLES_COUNT_PER_PAGE,
    articleSortBy = FAQ_SORT_TYPES.changedDown.code,
  ) {
    const params = { categoryId, articlesPageSize, articleSortBy };

    return $axios
      .get(`/faq/categories/${categoryId}/category-info/`, { params })
      .then((res) => res.data);
  },

  /**
   * Поиск статей для Базы Знаний (FAQ)
   * @param query {string} - поисковый запрос
   * @param page {number} - номер страницы поисковой выдачи, первая страница = 0
   * @param size {number} - размер страницы поисковой выдачи
   * @return {Promise<object>}
   */
  searchArticles(query, { page = 0, size = FAQ_ARTICLES_COUNT_PER_PAGE }) {
    if (!query) {
      throw new Error('Поле "query" обязательно для запроса "api/faq/articles/search"!');
    }

    const params = { query, page, size };

    return $axios
      .get('faq/articles/search', { params })
      .then((res) => res.data);
  },

  /**
   * Получение полной информации о статье Базы Знаний (FAQ)
   * @param id {number|string} - id статьи
   * @returns {Promise<object>}
   */
  getArticle(id) {
    return $axios
      .get(`faq/articles/${id}`)
      .then((res) => res.data);
  },

  /**
   * Получение статей конкретной категории
   * @param categoryId {number|string} - id категории
   * @param page {number} - номер страницы
   * @param size {number} - размер страницы
   * @param articleSortBy {string} - тип сортировки статей
   * @returns {Promise<array>}
   */
  getCategoryArticles(
    categoryId,
    page,
    size = FAQ_ARTICLES_COUNT_PER_PAGE,
    articleSortBy = FAQ_SORT_TYPES.changedDown.code,
  ) {
    const params = {
      categoryId,
      page,
      size,
      articleSortBy,
    };

    return $axios
      .get('faq/articles/by-category', { params })
      .then((res) => res.data);
  },

  /**
   * Получение массива родителей категории или статьи
   * @param categoryId {number} - id родительской категории
   * @returns {Promise<array>} - все родители категории или статьи
   */
  getParentsTree(categoryId) {
    return $axios
      .get(`faq/categories/${categoryId}/category-tree`)
      .then((res) => res.data);
  },
});
