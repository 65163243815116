export default ($axios) => ({
  userSearch(query = '') {
    return $axios
      .get('/business-trip/user-search', { params: { query } })
      .then((res) => res);
  },
  userSearchByNumber(params = {
    assignmentId: undefined,
    tabNumber: undefined,
  }) {
    return $axios
      .get('/business-trip/user-search-by-number', { params })
      .then((res) => res);
  },
  user(params = {
    assignmentNumber: undefined,
    email: undefined,
  }) {
    return $axios
      .get('/business-trip/user', { params })
      .then((res) => res);
  },
  updateTicket(id, data = null) {
    return $axios
      .put(`/business-trip/${id}`, data)
      .then((res) => res);
  },
  getAddresses(params = {
    searchType: undefined,
    cityCode: undefined,
    districtCode: undefined,
    localityCode: undefined,
    regionCode: undefined,
  }) {
    return $axios
      .get('/business-trip/addresses', { params })
      .then((res) => res);
  },
  getProgramCodes(params = {
    budgetArticle: undefined,
    budgetUnitCode: undefined,
    businessProcessId: undefined,
    consumerCFO: undefined,
  }) {
    return $axios
      .get('/business-trip/program-codes', { params })
      .then((res) => res);
  },
  getBusinessTripLimits() {
    return $axios
      .get('/business-trip/limits')
      .then((res) => res);
  },
  investProjectSearch(params = { query: undefined }) {
    return $axios
      .get('/business-trip/invest-project-search', { params })
      .then((res) => res);
  },
  investProjectSearchByCode(params = { projectCode: undefined }) {
    return $axios
      .get('/business-trip/invest-project-search-by-code', { params })
      .then((res) => res);
  },
  hotelSearch(params = { city: undefined }) {
    return $axios
      .get('/business-trip/hotels', { params })
      .then((res) => res);
  },
  getBusinessTripGoals() {
    return $axios
      .get('/business-trip/goals')
      .then((res) => res);
  },
  getFreeFund(params = {
    budgetArticle: undefined,
    budgetUnit: undefined,
    consumerCFO: undefined,
  }) {
    return $axios
      .get('/business-trip/free-funds', { params })
      .then((res) => res);
  },
  getConstants() {
    return $axios
      .get('/business-trip/constants')
      .then((res) => res);
  },
  citySearch(params = { query: undefined }) {
    return $axios
      .get('/business-trip/city-search', { params })
      .then((res) => res);
  },
  getCFOs() {
    return $axios
      .get('/business-trip/cfos')
      .then((res) => res);
  },
  getBookingRef({ ticketId, email }) {
    return $axios
      .get(`/business-trip/${ticketId}/getBookingRef`, {
        params: { email },
      })
      .then((res) => res);
  },
  getBusinessProcesses(params = {
    assignmentId: undefined,
    budgetArticle: undefined,
    budgetUnitCode: undefined,
    consumerCFO: undefined,
  }) {
    return $axios
      .get('/business-trip/business-processes', { params })
      .then((res) => res);
  },
  sendBusinessTrip(data) {
    return $axios
      .post('business-trip', data)
      .then((res) => res);
  },
  // отмена согласования юзером
  startEditing(id, params = { source: undefined }) {
    return $axios
      .patch(`/business-trip/${id}/start-editing?source=${params.source}`, { params })
      .then((res) => res);
  },
});
