/* eslint-disable import/prefer-default-export */

export const WINDOW_SIZES = {
  desktop: 1366,
  tabletLandscape: 1200,
  tabletPortrait: 1024,
  phone: 768,
};

export const VIEW_MODE = {
  regular: 'regular',
  onlyForm: 'onlyForm',
};
