import dateFormat from '@/filters/dateFormat';

const state = {
  // TODO: Статичные данные, заменить на данные с сервера
  articles: [
    {
      content: '<p>«Ростелеком» выступил партнером Дня Военно-Морского Флота в Санкт-Петербурге !!!!!</p>',
      editDatetime: 1646211213217,
      editorLogin: 'lev.royzenberg@rt.ru',
      files: Array[0],
      finishDatetime: 1650624542000,
      id: 23,
      isMain: true,
      isPublic: true,
      mainImageUrl: 'https://dummyimage.com/335/767/000.png',
      newsVersionId: 365,
      nodes: Array[0],
      previewContent: '«Ростелеком» выступил партнером',
      serviceIds: Array[1],
      startDatetime: 1636757545000,
      title: '«Ростелеком» выступил партнером Дня Военно-Морского Флота в Санкт-Петербурге',
    },
  ],
  loading: false,
};

const getters = {
  mappedArticles: (state) => state.articles.map((item) => ({
    ...item,
    url: `/articles/${item.id}`,
    additionalInfo: `Дата создания ${dateFormat(item.startDatetime, 'DD.MM.YYYY')}`,
  })),
};

const mutations = {
  SET_ARTICLES(state, val) {
    state.articles = val;
  },

  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
};

const actions = {
  async getArticles({ commit }, params) {
    try {
      const { data: articles } = await this.$API.articles.getArticles(params);

      commit('SET_ARTICLES', articles);

      return Promise.resolve(articles);
    } catch (e) {
      commit('SET_ARTICLES', []);

      return Promise.reject(e);
    }
  },
};

const articles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default articles;
