const state = {
  feasts: [],
  pageCount: 0,
  currentFeast: null,
};

const getters = {};

const mutations = {
  SET_FEASTS(state, val) {
    state.feasts = val;
  },
  SET_PAGE_COUNT(state, val) {
    state.pageCount = val;
  },
  SET_CURRENT_FEAST(state, val) {
    state.currentFeast = val;
  },
};

const actions = {
  getFeasts({ commit }, page = 0, size = 20) {
    return this.$API.feasts.getFeasts(page, size).then(({ data }) => {
      commit('SET_FEASTS', data.content);
      commit('SET_PAGE_COUNT', data.totalPages);
    }).catch((e) => {
      commit('SET_FEASTS', []);
      commit('SET_PAGE_COUNT', 0);
      return e;
    });
  },
  getFeast({ commit }, id) {
    return this.$API.feasts.getFeast(id).then(({ data }) => {
      commit('SET_CURRENT_FEAST', data);
    }).catch((e) => e);
  },
  clearFeast({ commit }) {
    commit('SET_CURRENT_FEAST', null);
  },
};

const feasts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default feasts;
