export default ($axios) => ({
  // Получение списка опросов обратной связи
  getQuestionary() {
    return $axios
      .get('/feedback/questionary')
      .then((res) => res);
  },
  // Создание или обновление существующего опросника обратной связи
  setQuestionary(data = null) {
    return $axios
      .post('/feedback/questionary', data)
      .then((res) => res);
  },
  // Получение конкретного опросника обратной связи
  getQuestionaryById(id) {
    return $axios
      .get(`/feedback/questionary/${id}`)
      .then((res) => res);
  },
  // Удаление определенного опроса обратной связи
  deleteQuestionary(id) {
    return $axios
      .delete(`/feedback/questionary/${id}`)
      .then((res) => res);
  },
  // Отмена опроса обратной связи
  rejectQuestionary(params) {
    return $axios
      .get('/feedback/questionary/reject', params)
      .then((res) => res);
  },
  // Ответ на опрос обратной связи
  answersQuestionary(answeredQuestions, params) {
    return $axios
      .post('/feedback/questionary/answers', answeredQuestions, params)
      .then((res) => res);
  },
  // Получение актуального опроса обратной связи
  actualQuestionary(params) {
    return $axios
      .get('/feedback/questionary/actual', params)
      .then((res) => res);
  },
  // Отложен опрос обратной связи
  fillLaterQuestionary(params) {
    return $axios
      .get('/feedback/questionary/fill-later', params)
      .then((res) => res);
  },
  // Получение результата опроса обратной связи
  getQuestionaryResult(id) {
    return $axios
      .post(`/feedback/questionary/${id}/export-results-to-excel`, {}, {
        responseType: 'blob',
      })
      .then((res) => res);
  },
});
