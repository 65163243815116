export default ($axios) => ({
  getDelegationList() {
    return $axios
      .get('/delegations')
      .then((res) => res);
  },
  createDelegation(data = null) {
    return $axios
      .post('/delegations', data)
      .then((res) => res);
  },
  getDelegationById(id) {
    return $axios
      .get(`/delegations/${id}`)
      .then((res) => res);
  },
  updateDelegation(id, data = null) {
    return $axios
      .put(`/delegations/${id}`, data)
      .then((res) => res);
  },
});
