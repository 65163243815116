/**
 * Генерирует ссылку на страницу создания тикета.
 * @param serviceId {string} - id тикета
 * @param sourceSystem {string} - код мастер-системы
 * @param templateId {number|string|null} - id шаблона
 * @return {string} - path до страницы создания тикета
 */
export const getCreateTicketPath = (serviceId, sourceSystem, templateId = null) => {
  if (!serviceId || !sourceSystem) {
    throw new Error('Параметры serviceId и sourceSystem обязательны!');
  }

  let path = `/tickets/create/${sourceSystem}/${serviceId}`;

  if (templateId) {
    path += `?templateId=${templateId}`;
  }

  return path;
};

/**
 * Генерирует ссылку на детальную страницу новости.
 * @param newsId {number} - id новости
 * @return {string} - ссылка на детальную новости
 */
export const getNewsArticlePath = (newsId) => `/news/${newsId}`;

/**
 * Генерирует ссылку на страницу редактирования формы в админке.
 * @param serviceId {number|string} - id услуги
 * @param id {number|string} - id формы
 * @returns {string} - ссылка на страницу редактирования формы
 */
export const getFormEditPath = (serviceId, id) => `/admin/forms/${serviceId}/edit/${id}`;
