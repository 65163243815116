import cloneDeep from 'lodash/cloneDeep';

const state = {
  templates: [],
};

const getters = {};

const mutations = {
  SET_TEMPLATES(state, val) {
    state.templates = val;
  },
};

const actions = {
  updateTemplates({ commit }, data) {
    commit('SET_TEMPLATES', data);
  },

  getUserTemplates({ dispatch }) {
    return this.$API.userTemplates.getUserTemplates().then(({ data }) => {
      dispatch('updateTemplates', data);
    }).catch((e) => {
      dispatch('updateTemplates', []);
      return e;
    });
  },

  async updateSortOrderTemplates({ state, dispatch }, templates) {
    const oldValue = cloneDeep(state.templates);

    dispatch('updateTemplates', templates);
    this.$API.userTemplates.updateUserTemplates(templates)
      .catch((e) => {
        dispatch('updateTemplates', oldValue);
        throw new Error(e);
      });
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
