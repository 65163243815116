import dateFormat from '@/filters/dateFormat';

const state = {
  news: [],
  newsCat: 'actual',
  loading: false,
  currentPage: 1,
  pageCount: 0,
};

const getters = {
  mappedNews: (state) => state.news.map((item) => ({
    ...item,
    url: `/news/${item.id}`,
    additionalInfo: `Дата создания ${dateFormat(item.startDatetime, 'DD.MM.YYYY')}`,
  })),
};

const mutations = {
  SET_NEWS(state, val) {
    state.news = val;
  },
  SET_NEWS_CAT(state, val) {
    state.newsCat = val;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_NEWS_PAGE_COUNT(state, val) {
    state.pageCount = val;
  },
  SET_CURRENT_PAGE(state, val) {
    state.currentPage = val;
  },
};

const actions = {
  async getNews({ commit }, params) {
    try {
      const { data } = await this.$API.news.getNews(params);

      commit('SET_NEWS', data.content);
      commit('SET_NEWS_PAGE_COUNT', data.totalPages);

      return Promise.resolve(data.content);
    } catch (e) {
      commit('SET_NEWS', []);

      return Promise.reject(e);
    }
  },
  setNewsCat({ commit }, cat) {
    commit('SET_NEWS_CAT', cat);
  },
  setCurrentPage({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
};

const system = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default system;
