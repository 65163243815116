export default ($axios) => ({
  escalate(id, data = null) {
    return $axios
      .post(`/escalations/escalate/${id}`, data)
      .then((res) => res);
  },
  getEscalationReasons(params = {
    source: undefined,
    ticketId: undefined,
  }) {
    return $axios
      .get('/escalations/reasons', { params })
      .then((res) => res);
  },
});
