const state = {
  query: '',
  isSearching: false,
};

const mutations = {
  SET_QUERY(state, query) {
    state.query = query;
  },
  SET_IS_SEARCHING(state, isSearching) {
    state.isSearching = isSearching;
  },
};

const actions = {
  setQuery({ commit }, query) {
    commit('SET_QUERY', query);
  },
  setIsSearching({ commit }, isSearching) {
    commit('SET_IS_SEARCHING', isSearching);
  },
};

const system = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default system;
