export default ($axios) => ({
  // КОНСТАНТЫ
  getApprovalTypes() {
    return $axios
      .get('/approvals/types')
      .then((res) => res);
  },
  getApprovalActions() {
    return $axios
      .get('/approvals/actions')
      .then((res) => res);
  },

  // АДМИНКА
  getApprovalForms(page = 0, size = 20) {
    return $axios
      .get(`/admin/approvals/forms?page=${page}&size=${size}`)
      .then((res) => res);
  },
  createApproval(data) {
    return $axios
      .post('/admin/approvals/forms', data)
      .then((res) => res);
  },
  updateApproval(data) {
    return $axios
      .put('/admin/approvals/forms', data)
      .then((res) => res);
  },
  getApprovalForm(id) {
    return $axios
      .get(`/admin/approvals/forms/${id}`)
      .then((res) => res);
  },
  getApprovalDefaultForm(actionType) {
    return $axios
      .get(`/admin/approvals/forms/default/${actionType}`)
      .then((res) => res);
  },
  deleteApprovalForm(id) {
    return $axios
      .delete(`/admin/approvals/forms/${id}`)
      .then((res) => res);
  },

  // КЛИЕНТ
  // получение тикет-согласование
  getApproval(id, params = {
    source: undefined,
  }) {
    return $axios
      .get(`/approvals/${id}`, { params })
      .then((res) => res);
  },
  // добавление согласующего
  add(id, data = null, params = { source: undefined }) {
    return $axios
      .post(`/approvals/manual/${id}`, data, { params })
      .then((res) => res);
  },
  getCheckList(id, params) {
    return $axios
      .get(`/approvals/checklist/${id}`, { params })
      .then((res) => res);
  },
  rejectTicketApproval(data = null, params = { source: 'otrs1' }) {
    return $axios
      .post(`/approvals/${params.id}/action/v.3/reject`, data, { params })
      .then((res) => res);
  },
  rejectTicketsApproval(data = null) {
    return $axios
      .post('/approvals/action/v.3/reject', data)
      .then((res) => res);
  },
  approveTicket(data = null, params = { source: 'otrs1' }) {
    return $axios
      .post(`/approvals/${params.id}/action/v.3/approve`, data, { params })
      .then((res) => res);
  },
  approveTickets(data = null) {
    return $axios
      .post('/approvals/action/v.3/approve', data)
      .then((res) => res);
  },
  // Добавление рекомендателя
  addRecommender(id, data = null, params = { source: undefined }) {
    return $axios
      .post(`/approvals/recommender/manual/${id}`, data, { params })
      .then((res) => res);
  },
});
