export default ($axios) => ({
  // Получение подробной информации о текущем пользователе
  current() {
    return $axios
      .get('/users/current')
      .then((res) => res);
  },
  /**
   * Получение индивидуальных настроек пользователя
   * @return {Promise<object>}
   */
  getUserSettings() {
    return $axios
      .get('/user-settings')
      .then((res) => res);
  },
  // Получение подробной информации о пользователе по логину
  info(employeeLogin) {
    return $axios
      .get(`/users/login/${employeeLogin}`)
      .then((res) => res);
  },
  // перевод информации о пользователе для визиток
  translationInfo(params = {
    address: undefined,
    positionRus: undefined,
  }) {
    return $axios
      .get('/users/get-translation-info', { params })
      .then((res) => res);
  },
  // Поиск пользователей
  search(params = {
    isFired: undefined,
    limit: undefined,
    pageNum: undefined,
    query: undefined,
    source: undefined,
    customEmployeeListId: undefined,
  }) {
    const path = params.customEmployeeListId ? '/users/search/customEmployeeList' : '/users/search';
    return $axios
      .get(path, { params })
      .then((res) => res);
  },
  // Получение всех ролей на портале (доступных для добавления пользователю в админке)
  getRoles() {
    return $axios
      .get('/admin/roles')
      .then((res) => res);
  },
  // Получение пользователей, у которых есть роли, отличные от USER
  getAdditionRolesUsers() {
    return $axios
      .get('/admin/user-roles')
      .then((res) => res);
  },
  addRole(employeeId, login, roles) {
    return $axios
      .post('/admin/user-roles',
        { employeeId, login, roles })
      .then((res) => res);
  },
  removeRole(employeeId, employeeLogin, roles) {
    return $axios
      .delete(`/admin/user-roles/${employeeLogin}`, {
        params: { employeeId, roles },
      })
      .then((res) => res);
  },
  // Получение списка привилегированными пользователями
  getPrivilegedUsers() {
    return $axios
      .get('/users/privileged-users')
      .then((res) => res);
  },
  addPrivilegedUser(employeeId, employeeLogin) {
    return $axios
      .post('/users/privileged-users',
        {
          employeeId,
          employeeLogin,
        })
      .then((res) => res);
  },
  removePrivilegedUser(employeeId, employeeLogin) {
    return $axios
      .delete('/users/privileged-users', {
        params: { employeeId, employeeLogin },
      })
      .then((res) => res);
  },
});
