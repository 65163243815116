export default (theming, { notification, theme, buttons }) => {
  /* eslint-disable no-param-reassign */
  // THEME
  theming.primaryColor = theme.activeColor;
  theming.primaryColorHover = theme.unActiveColor;
  theming.secondaryColor = theme.secondColor;

  // BUTTONS
  // primary
  theming.buttonViewPrimaryBackgroundColor = buttons.main.backgroundColor;
  theming.buttonViewPrimaryBackgroundColorHover = buttons.main.backgroundActionColor;
  theming.buttonViewPrimaryDisabledBackgroundColor = buttons.main.backgroundDisabledColor;
  theming.buttonViewPrimaryTextColor = buttons.main.textColor;
  // outline
  theming.buttonViewOutlineBorderColor = buttons.outline.frameColor;
  theming.buttonViewOutlineColor = buttons.outline.textColor;
  theming.buttonViewOutlineHoverBackgroundColor = buttons.outline.backgroundColor;
  theming.buttonViewOutlineHoverBorderColor = buttons.outline.frameActionColor;
  // function
  theming.buttonViewFunctionHoverTextColor = buttons.function.textActionColor;
  theming.buttonViewFunctionTextColor = buttons.function.textColor;
  // ghost
  theming.buttonViewGhostHoverTextColor = buttons.ghost.textActionColor;
  theming.buttonViewGhostTextColor = buttons.ghost.textColor;

  // NOTIFICATION
  theming.notifyAttentionBackgroundColor = notification.warningColor;
  theming.notifyErrorBackgroundColor = notification.errorColor;
  theming.notifyInfoBackgroundColor = notification.infoColor;
  theming.notifySuccessBackgroundColor = notification.successColor;
  /* eslint-enable no-param-reassign */
};
