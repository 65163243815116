export default ($axios) => ({
  // Получение списка колонок
  getColumns(data = {
    databaseUrl: undefined,
    databaseUser: undefined,
    columns: undefined,
  }) {
    return $axios
      .post('/control/database/query', data)
      .then((res) => res);
  },
  getDatabases() {
    return $axios
      .get('/control/database/connection-properties')
      .then((res) => res);
  },
  saveDatabaseConnection(data) {
    return $axios
      .post('/control/database/connection-properties', data)
      .then((res) => res);
  },
  removeDatabaseConnection(databaseId, databaseUrl) {
    const params = {
      databaseId,
      databaseUrl,
    };
    return $axios
      .delete('/control/database/connection-properties', { params })
      .then((res) => res);
  },
  getTablesList(databaseId, query) {
    const params = {
      databaseId,
      query,
    };
    return $axios
      .get('/control/database/tables', { params })
      .then((res) => res);
  },
  getTableColumns(databaseId, tableName, query) {
    const params = {
      databaseId,
      tableName,
      query,
    };
    return $axios
      .get('/control/database/columns', { params })
      .then((res) => res);
  },
});
