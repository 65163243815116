export default ($axios) => ({
  getWorkflows(serviceId, source = 'otrs1') {
    return $axios
      .get(`/admin/workflows?source=${source}&serviceId=${serviceId}`)
      .then((res) => res);
  },
  getQueues(serviceId, source = 'otrs1') {
    return $axios
      .get(`/admin/queues?source=${source}&serviceId=${serviceId}`)
      .then((res) => res);
  },
});
