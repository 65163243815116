export default ($axios) => ({
  getClasses(params = {
    source: 'otrs1',
  }) {
    return $axios
      .get('/cmdb/classes', { params })
      .then((res) => res);
  },
  getAttributes(params = {
    source: 'otrs1',
  }) {
    return $axios
      .get('/cmdb/attributes', { params })
      .then((res) => res);
  },
  /**
   * Получение списка КЕ, относящихся к одному классу
   * @link https://ci-01-otrs.rtk-dc.ru/helpme/helpme-docs/-/wikis/cmdb-описание-работы
   * @param className {string} - класс (справочник) для получения списка КЕ, которые содержатся в этом классе
   * @param linkedConfigItem {number} - id КЕ, которое должно иметь связь с получаемыми КЕ
   * @param attributeNameAsItemName {string} - если указать в данном параметре название атрибута,
   * то значение указанного атрибута будет подставлено в поле "itemContent" при ответе данного эндпоинта
   * @param configItemFilter {object[]} - механизм фильтрации списка КЕ
   * @param source {string} - мастер система, обычно otrs1
   * @param query {string} - поисковый запрос для получения списка КЕ, если параметр isSearch = true
   * @return {array} - список КЕ
   */
  getConfigItems({
    className,
    linkedConfigItem,
    attributeNameAsItemName,
    configItemFilter,
    source = 'otrs1',
    query = undefined,
  } = {}) {
    // TODO: сделать валидацию configItemFilter
    const params = {
      className,
      linkedConfigItem,
      attributeNameAsItemName,
      configItemFilter,
      source,
      query,
    };

    return $axios
      .get('/cmdb/config-items/', { params })
      .then((res) => res.data);
  },
});
